import { Component, Input, OnInit } from '@angular/core';

export type DefaultCardUsavingsParams = {
  headerTitle: string;
  description?: string;
  loading?: boolean;
  loadingMessage?: string;
  heightBody: string;
}

@Component({
  selector: 'default-card-usavings',
  templateUrl: './default-card-usavings.component.html',
  styleUrls: ['./default-card-usavings.component.scss']
})
export class DefaultCardUsavingsComponent implements OnInit {

  @Input() headerTitle: string;
  @Input() description: string = undefined;
  @Input() loading: boolean = undefined;
  @Input() loadingMessage: string = undefined;
  @Input() heightBody = "100%";

  constructor() { }

  ngOnInit() {
  }

}
