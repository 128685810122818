import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'float-button',
  templateUrl: './float-button.component.html',
  styleUrls: ['./float-button.component.scss']
})
export class FloatButtonComponent implements OnInit {

  @Input() templateRef: ElementRef;
  @Input() placement = 'left';
  @Input() classIcon = 'fas fa-question';

  constructor() { }

  ngOnInit() {
  }

}
