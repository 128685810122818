import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'aws-icons',
  templateUrl: './aws-icons.component.html',
  styleUrls: ['./aws-icons.component.scss']
})
export class AwsIconsComponent implements OnInit {

  @Input() service;
  @Input() size = 32;
  @Input() color = 'black';

  constructor() { }

  ngOnInit() {
  }

}
