import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultLoadingUsavingsComponent } from './components/default-loading-usavings/default-loading-usavings.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AppTranslationModule } from '../../components/app.translation.module';

const COMPONENTS = [DefaultLoadingUsavingsComponent]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    AppTranslationModule
  ],
  exports: [...COMPONENTS]
})
export class LoadingModule { }
