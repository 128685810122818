import IContract from "../interfaces/contract.interface";
import { IUser } from "../interfaces/user.interface";

export default class User {
    isAdmin: boolean
    userId: number
    userUuid: string
    defaultCompany: string
    

    constructor(params: IUser) {
        this.fromJson(params);
      }
    
    fromJson(params: IUser) {
        this.isAdmin = params.isAdmin;
        this.userId = params.userId;
        this.userUuid = params.userUuid;
        this.defaultCompany = params.defaultCompany;
    }

    toJson(): IUser {
        return {
            isAdmin: this.isAdmin,
            userId: this.userId,
            userUuid: this.userUuid,
            defaultCompany: this.defaultCompany,
        }
    }


}