import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CurrencyFormatPipe } from "./currency-format.pipe";
import { FileSizePipe } from "./filesize.pipe";
import { OrderByPipe } from "./orderBy.pipe";
import { SearchPipe } from "./search.pipe";
import { SizePipe } from "./size.pipe";
import { AbsolutePipe } from "./absolute.pipe";
import { isPrivatePipe } from "./typeCloud.pipe";

const PIPES = [CurrencyFormatPipe, OrderByPipe, SizePipe, FileSizePipe, SearchPipe, AbsolutePipe,isPrivatePipe];

@NgModule({
  declarations: [...PIPES],
  imports: [CommonModule],
  exports: [...PIPES],
})
export class PipesModule {
  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
