import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NbLoginComponent, NbAuthComponent } from '@nebular/auth';
import { AppSettingsComponent } from './app-settings.component';
import { AppSettingsLayoutComponent } from './app-settings-layout.component';

const routes: Routes = [
        {
          path: '',
          component: AppSettingsLayoutComponent,
          children:[
              {
                  path: '',
                  component: AppSettingsComponent
              }
            ]
        },
      ]

@NgModule({
    imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppSettingsRoutingModule {
}
