import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppTranslationModule } from '../../components/app.translation.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { DefaultListUsavingsComponent } from './components/default-list-usavings/default-list-usavings.component';

const COMPONENTS = [DefaultListUsavingsComponent]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    AppTranslationModule,
    ButtonsModule
  ],
  exports: [...COMPONENTS]
})
export class UsavingsListModule { }
