export class CSVRecord {
  public name: any;
  public memory: any;
  public vCPU: any;
  public price: any;
  public currency:string;
  public osType: string;
  public location: string;
}
export class CSVKubernetesRecord {
  public name: string
  public clusters: number
  public cpus: number
  public memory: number
  public instances: number
}
export class CSVLoadBalancerRecord {  
  public name: any;  
  public instances: any;  
  public rules: any;  
  public data: any;
  public type: any;
  public locations: any;
}

export class CSVStorageRecord {
  public name: any;
  public instance: any;
  public transaction: any;
  public size: any;
}

export class CSVIPRecord {
  public name: any;
  public ips: any;
}

export class CSVDBRecord {
  public name: any;
  public vcpus: any;
  public memory: any;
  public storage: any;
  public isMultiZone: any;
  public databaseEngine: any;
}