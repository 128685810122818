import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { AppTranslationModule } from "../app.translation.module";

@Component({
  selector: "ustore-select-search",
  templateUrl: "./select-search.component.html",
  styleUrls: ["./select-search.component.scss"],
})
export class SelectSearchComponent implements OnInit, OnChanges {
  custom_class: string = "hide";
  @Input() list: any = [];
  @Input() name_field: string = "name";
  @Input() id_field: string = "ucloudidentifier";
  @Input() defaultCostValid = true;
  @Input() icon: string = "";
  @Input() containerWithVms: any;
  @Input() placeholder: any;
  @Input() hasError: boolean = false;
  @Input() messageError: string = '';
  @Input() disabled: boolean = false;
  arr_list: any = [];
  selectSearchInput: string = "";
  @Output() selectedData: any = [];
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onClear: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchInput') searchInput;

  languageSelected: any;

  constructor(private i18n:I18n,
    private translate: AppTranslationModule) {
  }

  async ngOnInit() {
    this.languageSelected = this.translate.getLanguageSession();
    this.placeholder = await this.translate.getTranslation("Search");
    if (!this.list.length) {
      //load mock
      /*this.list = this.arr_list = [
        { name: "Azure-Kroton-Jornada-Administra", value: "1" },
        { name: "AWS-KrotonKorg", value: "2" },
        { name: "Azure-Kroton-vse-anderson_sant", value: "3" },
      ];*/
    } else {
      this.arr_list = this.list;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.list?.currentValue) {
        this.selectSearchInput = "";
      }
  }

  isPrivate(type){
    return ["vmware",'hyper-v'].includes(type);
  }

  focus() {
    this.custom_class = "show";
    if (this.selectSearchInput.length == 0) {
      this.arr_list = this.list;
    }
  }
  focusOut() {
    setTimeout(() => {
      this.custom_class = "hide";
    }, 100);
  }

  search(event: KeyboardEvent) {
    if (!this.hasError) {
      if (event.keyCode === 13) {
        if (this.arr_list.length) {
          this.selectSearchInput = this.arr_list[0][this.name_field];
          this.focusOut();
        }
      }
      this.arr_list = this.list.filter((item) => {
        return item[this.name_field]
          .toLowerCase()
          .includes(this.selectSearchInput.toLowerCase());
      });
    }
  }

  itemSelected(item) {
    this.selectedData = item[this.id_field];
    this.selectSearchInput = item[this.name_field];
    this.onSelect.emit(item);
  }

  clear() {
    if (this.list.length) {
      setTimeout(() => {
        this.arr_list = this.list;
      }, 300);
    }
    this.selectSearchInput = "";
    this.focusOut();
    this.onClear.emit();
  }

  disabledDefaultSelectAction(){
    return false;
  }
}
