import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, take } from 'rxjs/operators';

import { HttpQueueService } from '../../queue/http-queue.service';
import { compareModel } from '../models/compareModel';
import { compareModelBilling } from '../models/compareModelBilling';
import { RequestResult } from '../models/request-result';
import { ConfigService } from './config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { StateComponentService } from './stateComponent.service';
import { ResponseUsavings } from '../interfaces/ResponseUsavings';
import IContainer from '../interfaces/container.interface';
import Container from '../entities/Container';
import GlobalDataUsavings from '../interfaces/GlobalDataUsavings.interface';
import { StatusData } from '../enum/EnumStatusData';

@Injectable()
export class ContainerService extends StateComponentService{

  private containers: BehaviorSubject<GlobalDataUsavings<Container[]>> = new BehaviorSubject({
    data: [],
    status: StatusData.LOADING,
  });

  private currentContainer: BehaviorSubject<GlobalDataUsavings<Container>> = new BehaviorSubject({
    data: undefined,
    status: StatusData.LOADING
  });

  constructor(private http: HttpClient, private config: ConfigService, private httpQueue: HttpQueueService) {
    super();
  }

  getCurrentContainer(): Observable<GlobalDataUsavings<Container>> {
    return this.currentContainer.asObservable();
  }

  setCurrentContainer(container: GlobalDataUsavings<Container>) {
    this.currentContainer.next(container);
  }

  getContainers(): Observable<GlobalDataUsavings<Container[]>> {
    return this.containers.asObservable()
  }

  resetCache() {
    sessionStorage.removeItem('containers')
    sessionStorage.removeItem('currentContainer')
  }

  startLoading() {
    super.startLoading()
    this.containers.next({
      data: [],
      status: StatusData.LOADING
    })
    this.containers.next({
      data: undefined,
      status: StatusData.LOADING
    })
  }

  async loadContainers(companyUuid: string | undefined) {
    try {
      this.startLoading();

      if (!companyUuid) {
        this.containers.next({
          data: [],
          status: StatusData.EMPTY
        })
        this.currentContainer.next({
          data: undefined,
          status: StatusData.EMPTY
        })

        return
      }


      const cacheContainers = sessionStorage.getItem('containers')
      if (!cacheContainers) {
        this.resetCache()

        const responseObservable: Observable<RequestResult<ResponseUsavings<Container[]>>> = this.http
        .get(
          this.config.getUrlUsavings("/container"),
          this.config.getHttpOptions({
            tenant: companyUuid,
          })
        )
        .pipe(
          take(1),
          map((x: IContainer[]) => new RequestResult<ResponseUsavings<Container[]>>(true, x.map(container => new Container(container)))),
          catchError((err) => this.config.handleError(err))
        )

        responseObservable.toPromise().then(containers => {
          if (containers.isSuccess()) {
            let contractContainers: GlobalDataUsavings<Container[]> = {
              data: [],
              status: StatusData.EMPTY
            }
            
            if (containers.getResponse().length > 0 ){
              contractContainers = {
                data: containers.getResponse(),
                status: StatusData.READY
              }
            } 

            this.errorStatus(false);
            this.containers.next(contractContainers);

            sessionStorage.setItem('containers', JSON.stringify(contractContainers))

            this.loadCurrenctContainer(contractContainers.data)
          } else {
            this.errorStatus(true);
            //TODO toast error
            this.containers.next({
              data: [],
              status: StatusData.ERROR,
              error: {error: true, type: containers.getErrorType(), message: containers.getMessage()}
            });

            this.loadCurrenctContainer(undefined)
          }

          
        })
      } else {
        const containers = JSON.parse(cacheContainers) as GlobalDataUsavings<Container[]>
        this.containers.next(containers)
        
        this.loadCurrenctContainer(containers.data)
      }
      
      this.stopLoading();
      

      return 
    } catch (error) {
      //TODO toast error
      this.containers.next({
        data: [],
        status: StatusData.ERROR,
        error: {error: true, type: "UnknowError", message: "An unexpected error occur, try later!"}
      });
    }
  }

  saveCurrentContainerOnSessionStorage(container: GlobalDataUsavings<Container> ) {
    sessionStorage.setItem('currentContainer', JSON.stringify(container));
  }

  loadCurrenctContainer(containers: Container[]) {

    try {
      if (containers === undefined){
        this.setCurrentContainer({
          data: undefined,
          status: StatusData.ERROR,
          error: {error: true, type: "EmptyContainers", message: "List of containers is empty"}
        })
        return
      }
  
      const cacheCurrentContainer = sessionStorage.getItem('currentContainer');
      if (!cacheCurrentContainer) {
        let currenctContainer: GlobalDataUsavings<Container> 
        if (containers.length > 0) {
          currenctContainer = {
            data: containers[0],
            status: StatusData.READY
          }
        } else {
          currenctContainer = {
            data: undefined,
            status: StatusData.EMPTY
          }
        }
        this.setCurrentContainer(currenctContainer)
        this.saveCurrentContainerOnSessionStorage(currenctContainer)
  
      } else {
        this.setCurrentContainer(JSON.parse(cacheCurrentContainer))
      }
    } catch (error) {
      //TODO toast error
      this.currentContainer.next({
        data: undefined,
        status: StatusData.ERROR,
        error: {error: true, type: "UnknowError", message: "An unexpected error occur, try later!"}
      })
    }

    
  }

  getValidDefaultCost(companyUuid: any){
    return this.http.get(this.config.getUrlUsavings('/compare/contract/defaultcost'), this.config.getHttpOptions({
      'uuid': companyUuid,
    })).pipe(take(1),
    map(x => new RequestResult(true, x)), catchError(err => this.config.handleError(err))).toPromise();
  }

  getContainer(companyUuid: any) {
    return this.http.get(this.config.getUrlUsavings('/contract/containers'), this.config.getHttpOptions({
      tenant: companyUuid
    })).pipe(take(1),
    map(x => new RequestResult(true, x)), catchError(err => this.config.handleError(err))).toPromise();
  }

  getContractContainersWithFlavor(companyUuid: any) {
    return this.http.get(this.config.getUrlUsavings('/cloud/contractsFlavors'), this.config.getHttpOptions({
      tenant: companyUuid
    })).pipe(take(1),
    map(x => new RequestResult(true, x)), catchError(err => this.config.handleError(err))).toPromise();
  }

  async getContractContainersWithVms(companyUuid: any) {
    return this.http.get(this.config.getUrlUsavings('/contract/containersWithVm'), this.config.getHttpOptions({
      tenant: companyUuid
    })).pipe(take(1),
    map(x => new RequestResult(true, x)), catchError(err => this.config.handleError(err))).toPromise();
  }

  getVmsUUIDByContainer(UUID: any) {
    return this.http.get(this.config.getUrlUsavings(`/container/vms/light/${UUID}`), this.config.getHttpOptions()).pipe(take(1));
  }

  getVmsUUIDByContainerWithRegion(UUID: string,type: string,contractUuid: string) {
    return this.http.get(this.config.getUrlUsavings(`/compare/vms/light`), this.config.getHttpOptions({
      ucloudidentifier: UUID,
      contractUuid,
      type: type
    })).pipe(take(1),
      map(x => {
        return new RequestResult(true, x);
      }),
      catchError((err) => {
        return this.config.handleError(err);
      }),
    );
  }

  getSuggestionByVm(UUID: String, startDate: String, endDate: String) {
    return this.http.get(this.config.getUrl('/billing/' + UUID + '/suggested-flavor'),
      this.config.getHttpOptions({
        'startDate': startDate,
        'endDate': endDate,
      })).pipe(
      take(1),
      map(x => {
        return new RequestResult(true, x);
      }),
      catchError((err) => {
        return this.config.handleError(err);
      }),
    );
  }

    getCompareByVm(compareObject: compareModel,contractUuid: string) {
    return this.httpQueue.invoke(this.config.getUrlUsavings("/compare/container"), 'GET', '' , this.config.getHttpOptions({
      'uuidVm': compareObject.ucloudidentifier,
      'clouds': compareObject.compareClouds,
      'uuid': contractUuid,
      'regions': JSON.stringify(compareObject.regions) ? JSON.stringify(compareObject.regions) : '',
    }), "")
    .pipe(
      take(1),
      map(x => {
        if(x.hasOwnProperty("error")) return new RequestResult(false, x);
        return new RequestResult(true, x);
      }),
      catchError((err) => {
        return this.config.handleError(err);
      }),
    );
  }

  getCompareByBilling(object:compareModelBilling, contractUuid: string) {
    return this.httpQueue.invoke(this.config.getUrlUsavings(`/compare/billing/${object.currentCloud}`),     'GET', '' , this.config.getHttpOptions({
      'startDate': object.startDate,
      'endDate': object.endDate,
      'uuid': object.containerUuid,
      'paginateConf': JSON.stringify({offset: object.offset}),
      'clouds': object.compareClouds,
      'regions': JSON.stringify(object.regions),
      'contractUuid': contractUuid
    }), '')
    .pipe(
      take(1),
      map(x => {
        return new RequestResult(true, x);
      }),
      catchError((err) => {
        console.log(err);
        return this.config.handleError(err);
      }),
    );
  }

  getSuggestedFlavorForVmByCloud(uuid, cloud, startDate, endDate) {
    var isValid: boolean = true;
    cloud = cloud.toLowerCase();
    switch (cloud) {
      case 'aws':
        isValid = true
        break;
      case 'embratel':
        isValid = true
        break;
      case 'azure':
        isValid = true
        break;
      case 'google':
        isValid = true
        break;
      case 'ibm':
        isValid = true
        break;
      default:
        isValid = false
        break;
    }

    if (isValid == true)
      return this.http.get(this.config.getUrl('/usavings/vm/' + uuid + '/compare/' + cloud), this.config.getHttpOptions({
        startDate: startDate,
        endDate: endDate
      })).pipe(take(1));
    else
      return null;
  }

  getComparerActual(UUID) {
    return this.http.get(this.config.getUrl('/usavings/vm/' + UUID + '/getActual'), this.config.getHttpOptions()).pipe(take(1));
  }

  // TODO atualizar a rota para utilizar na nova arquitetura
  getVmPerformanceProm(UUID: string) {
    return this.http.get(this.config.getUrlUsavings(`/vm/performance`), this.config.getHttpOptions({
      ucloudidentifier: UUID
    })).pipe(
      take(1),
      map(
        x => new RequestResult(true, x)), catchError(err => this.config.handleError(err)
      )
    ).toPromise();
  }

  getVmPerformanceMonthDetail(UUID: string, month: number) {
    return this.http.get(
      this.config.getUrlUsavings(`/vm/performance/monthly/${month}`),
      this.config.getHttpOptions({ ucloudidentifier: UUID })).pipe(take(1), map( x => new RequestResult(true, x)), catchError(err => this.config.handleError(err))
    ).toPromise();
  }

  getRegionsByContainer(uuid: string) {
    return this.http.get(this.config.getUrlUsavings(`/container/region/${uuid}`)).pipe(take(1), map(x => new RequestResult(true, x)),
    catchError(err => this.config.handleError(err))).toPromise();
  }

  getRegionsByCloudName(cloudName: string) {
    return this.http.get(
      this.config.getUrlUsavings(`/compare/regions`),
      this.config.getHttpOptions({ cloudName })
    ).pipe(take(1), map(x => new RequestResult(true, x)),
    catchError(err => this.config.handleError(err))).toPromise();
  }

  getCountVmsContainer(object: any) {
    const url = `/compare/billing/resourcecount`;
    return this.httpQueue.invoke(this.config.getUrlUsavings(url), 'GET', '' , this.config.getHttpOptions({
      'startDate': object.startDate,
      'endDate': object.endDate,
      'uuids': object.containerUuids
    }), '')
    .pipe(take(1), map(x => new RequestResult(true, x)),
    catchError(err => this.config.handleError(err))).toPromise();
  }

  async getContainersWithCredentials(contractUuid: string) {

    return this.http.get(this.config.getUrlUsavings(`/container/withCredentials/${contractUuid}`)).pipe(take(1),
      map(x => new RequestResult(true, x)),
      catchError(err => this.config.handleError(err))).toPromise();
  }

  async getContainersByCloud(type: any) {
    const contract = JSON.parse(sessionStorage.getItem("currentContract")).uuid;
    return this.http.get(this.config.getUrlUsavings(`/container/list`), this.config.getHttpOptions({
      "contract": contract,
      "type": type.name
    })).pipe(take(1),
      map(x => new RequestResult(true, x)),
      catchError(err => this.config.handleError(err))).toPromise();
  }
  getAccountsBillingByContainersUUIDs(containersUuids: string[]): Promise<RequestResult> {
    return this.http.post(
      this.config.getUrlUsavings(`/compare/billing/accounts`),
      {
        'containers': containersUuids
      },
      this.config.getHttpOptions()
      ).pipe(take(1),
      map(x => new RequestResult(true, x)),
      catchError(err => this.config.handleError(err))).toPromise();
  }
  
}
