import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import Contract from '../entities/Contract';
import { StatusData } from '../enum/EnumStatusData';
import GlobalDataUsavings from '../interfaces/GlobalDataUsavings.interface';
import { ResponseUsavings } from '../interfaces/ResponseUsavings';
import IContract from '../interfaces/contract.interface';
import { IUser } from '../interfaces/user.interface';
import { RequestResult } from '../models/request-result';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private loading = new BehaviorSubject(false);
  private hasError = new BehaviorSubject(false);

  private contracts: BehaviorSubject<GlobalDataUsavings<Contract[]>> = new BehaviorSubject({
    data: [],
    status: StatusData.LOADING
  });

  private currentContract: BehaviorSubject<GlobalDataUsavings<Contract>> = new BehaviorSubject({
    data: undefined,
    status: StatusData.LOADING
  });

  constructor(
    private http: HttpClient,
    private config: ConfigService,
  ) {}

  // ! ONLY USE IN PIPE 
  getCurrencyContract(): string {
    if (this.currentContract.value.status === StatusData.READY) {
      return this.currentContract.value.data.currency
    }

    return null
  }

  getCurrentContract(): Observable<GlobalDataUsavings<Contract>> {
    return this.currentContract.asObservable();
  }

  setCurrentContract(contract: GlobalDataUsavings<Contract>, toCache: boolean = false) {

    if (toCache) {
      if (!contract.data.currency) contract.data.currency = this.config.getDefaultCurrency();
      sessionStorage.setItem('currentContract', JSON.stringify(contract))
    }

    this.currentContract.next(contract);
  }

  getContracts(): Observable<GlobalDataUsavings<Contract[]>> {
    return this.contracts.asObservable();
  }

  getHasError(): Observable<boolean> {
    return this.hasError.asObservable();
  }

  setHasError(status: boolean) {
    this.hasError.next(status);
  }

  getLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  private startLoading(): void{
    this.loading.next(true)
  }

  private stopLoading(): void{
    this.loading.next(false)
  }

  resetCache() {
    sessionStorage.removeItem('contracts')
    sessionStorage.removeItem('currentContract')
  }

  async loadContractData(user: IUser | undefined) {

    this.startLoading();

    if (!user) {
      this.contracts.next({
        data: [],
        status: StatusData.EMPTY
      })
      this.currentContract.next({
        data: undefined,
        status: StatusData.EMPTY
      })

      return
    }

    const cacheContracts = sessionStorage.getItem('contracts')

    if (!cacheContracts) {
      this.resetCache()

      const responseObservable: Observable<RequestResult<ResponseUsavings<Contract[]>>> = this.http
      .get(
        this.config.getUrlUsavings("/contract"),
        this.config.getHttpOptions()
      )
      .pipe(
        take(1),
        map((x: IContract[]) =>  new RequestResult<ResponseUsavings<Contract[]>>(true, x.map(contract => new Contract(contract)))),
        catchError(err => this.config.handleError(err))
        )

    responseObservable.toPromise().then(contracts => {
      if (contracts.isSuccess()) {
        let contractsUser: GlobalDataUsavings<Contract[]> = {
          data: [],
          status: StatusData.EMPTY
        }

        if (contracts.getResponse().length > 0 ){
          contractsUser = {
            data: contracts.getResponse(),
            status: StatusData.READY
          }
        }

        this.setHasError(false);
        this.contracts.next(contractsUser);

        sessionStorage.setItem('contracts', JSON.stringify(contractsUser))

        this.loadActualContract(contractsUser.data, user)
      } else {
        this.setHasError(true);
        //TODO toast error
        this.contracts.next({
          data: [],
          status: StatusData.ERROR
        });

        this.loadActualContract(undefined,user)
      }
    })
    } else {
      const contracts = JSON.parse(cacheContracts) as GlobalDataUsavings<Contract[]>
      this.contracts.next(contracts)

      this.loadActualContract(contracts.data, user)
    }

    this.stopLoading();
  }

  loadActualContract(contracts: Contract[] | undefined, user: IUser) {

    try {
      if (contracts === undefined){
        this.setCurrentContract({
          data: undefined,
          status: StatusData.ERROR
        })
        return
      }

      const cacheActualContract = sessionStorage.getItem('currentContract');

      if (!cacheActualContract) {
        let currenctContract: GlobalDataUsavings<Contract>
        if (user && contracts.length > 0) {
          let actualContract = contracts.find(contract => user.defaultCompany === contract.ucloudIdentifier)

          if (!actualContract) {
            actualContract = contracts[0];
          }

          currenctContract = {
            data: actualContract,
            status: StatusData.READY
          }

        } else {
          currenctContract = {
            data: undefined,
            status: StatusData.EMPTY
          }
        }
        this.setCurrentContract(currenctContract,true)
      } else {
        this.setCurrentContract(JSON.parse(cacheActualContract))
      }
    } catch (error) {
      this.setCurrentContract({
        data: undefined,
        status: StatusData.ERROR
      })
    }


  }
}
