import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'elastic-compute-cloud',
  templateUrl: './elastic-compute-cloud.component.html',
  styleUrls: ['./elastic-compute-cloud.component.scss']
})
export class ElasticComputeCloudComponent implements OnInit {

  @Input() size = 32;
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

}
