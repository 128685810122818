import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloudTrailComponent } from './cloud-trail/cloud-trail.component';
import { AwsIconsComponent } from './aws-icons.component';
import { ElasticComputeCloudComponent } from './elastic-compute-cloud/elastic-compute-cloud.component';
import { ElasticLoadBalancerComponent } from './elastic-load-balancer/elastic-load-balancer.component';
import { RelationalDatabaseComponent } from './relational-database/relational-database.component';
import { DatabaseMigrationComponent } from './database-migration/database-migration.component';
import { DynamodbComponent } from './dynamodb/dynamodb.component';
import { ElasticBlockStorageComponent } from './elastic-block-storage/elastic-block-storage.component';
import { ElasticFileSystemComponent } from './elastic-file-system/elastic-file-system.component';
import { ElasticacheComponent } from './elasticache/elasticache-icon.component';
import { NeptuneComponent } from './neptune/neptune-icon.component';
import { OpensearchIconComponent } from './opensearch-icon/opensearch-icon.component';
import { VpcIconComponent } from './vpc-icon/vpc-icon.component';

const COMPONENTS = [
  CloudTrailComponent,
  AwsIconsComponent,
  ElasticComputeCloudComponent,
  ElasticLoadBalancerComponent,
  RelationalDatabaseComponent,
  DatabaseMigrationComponent,
  DynamodbComponent,
  ElasticBlockStorageComponent,
  ElasticFileSystemComponent,
  ElasticacheComponent,
  NeptuneComponent,
  OpensearchIconComponent,
  VpcIconComponent,
]
const MODULES = [CommonModule,]

@NgModule({
    declarations: [...COMPONENTS, ],
    imports: [
      ...MODULES
    ],
    exports: [
        ...MODULES,...COMPONENTS
    ]
  })
export class AwsIconsModule { }
