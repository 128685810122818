import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit,OnChanges {

  @Input() iconClass;
  @Input() theme = 'main';
  @Input() weight = 'light'
  @Input() size = 'small'
  @Input() shape: 'block' | 'tiny-rounded' | 'rounded' | 'circle' = 'block'
  @Input() selected = false;
  @Input() disabled = false

  @Output() onClick = new EventEmitter<void>();

  classObject = {}

  constructor() { }

  ngOnInit() {
    this.classObject[this.shape] = true;
    this.classObject[this.weight] = true;
    this.classObject[this.size] = true;
    this.classObject['mainThemeButton'] = this.theme === 'main';
    this.classObject['darkThemeButton'] = this.theme === 'dark'
    this.classObject['selected'] = this.selected;
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach(param => {
      this.classObject[param] = changes[param].currentValue
    })
  }

  clickButton() {
		this.onClick.emit();
	}
}
