import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'default-loading-usavings',
  templateUrl: './default-loading-usavings.component.html',
  styleUrls: ['./default-loading-usavings.component.scss']
})
export class DefaultLoadingUsavingsComponent implements OnInit {

  @Input() message: string = undefined;

  constructor() { }

  ngOnInit() {
  }

}
