import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';

import {
  NbAccordionModule, NbActionsModule, NbAlertModule, NbButtonModule, NbCalendarModule,
  NbCalendarRangeModule, NbCardModule, NbCheckboxModule, NbContextMenuModule, NbDialogModule, NbInputModule, NbLayoutModule, NbListModule, NbMenuModule, NbPopoverModule, NbProgressBarModule, NbRadioModule, NbRouteTabsetModule,
  NbSearchModule, NbSelectModule, NbSidebarModule, NbSpinnerModule, NbStepperModule, NbTabsetModule,
  NbThemeModule, NbToastrModule, NbTooltipModule, NbUserModule, NbWindowModule
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';

import { AppTranslationModule } from '../components/app.translation.module';
import {
  FooterComponent,
  HeaderComponent, LayoutDirectionSwitcherComponent, SearchInputComponent, SwitcherComponent, ThemeSettingsComponent, ThemeSwitcherComponent,
  ThemeSwitcherListComponent
} from './components';
import { ModalChangeRoleComponent } from './components/header/modal-change-role/modal-change-role.component';
import {
  OneColumnLayoutComponent
} from './layouts';
import {
  CapitalizePipe, EvaIconsPipe, NumberWithCommasPipe, PluralPipe,
  RoundPipe,
  TimingPipe
} from './pipes';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { DEFAULT_THEME } from './styles/theme.default';
import { SERPRO_THEME } from './styles/theme.serpro';
import { TELMEX_THEME } from './styles/theme.telmex';
import { PRODEMGE_THEME } from './styles/theme.prodemge';
import { ContainerSelectorComponent } from './components/container-selector/container-selector.component';
import {MatMenuModule} from '@angular/material/menu';


const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, AppTranslationModule];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule, // *nbIsGranted directive,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule,
  NgxPaginationModule,
  AppTranslationModule,
  MatMenuModule
];

const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  ThemeSwitcherComponent,
  ThemeSwitcherListComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  ModalChangeRoleComponent,
  ContainerSelectorComponent,

  OneColumnLayoutComponent, // Esse é o layout escolhido
];

const ENTRY_COMPONENTS = [
  ThemeSwitcherListComponent,
  ModalChangeRoleComponent
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default',
    },
    [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, TELMEX_THEME, SERPRO_THEME,PRODEMGE_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES],
  entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
