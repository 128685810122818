import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'usavings-button',
  templateUrl: './usavings-button.component.html',
  styleUrls: ['./usavings-button.component.scss']
})
export class UsavingsButtonComponent implements OnInit {

  @Input() theme = 'main';
  @Input() width = 'auto';
  @Input() height = 'auto';
  @Input() textAlign = 'left'
  @Input() leftIcon = undefined
  @Input() rightIcon = undefined
  @Input() colorIconTheme = "black"
  @Input() colorBorderTheme = "default-border"
  @Input() borderWidth = "tiny-border"
  @Input() padding = "16px"
  @Input() fontSize = "16px"
  @Input() weight: 'light' | 'filled' = 'light'
  @Input() shape: 'block' | 'tiny-rounded' | 'round' = 'block'
  @Input() selected = false;
  @Input() disabled = false;
  @Input() asSubmit = false

  @Output() onClick = new EventEmitter<void>();

  classObject = {}
  classColorIcon = {}

  constructor() { }


  ngOnInit() {
    this.classObject[this.shape] = true;
    this.classObject[this.weight] = true;
    this.classObject[this.borderWidth] = true;
    this.classObject[this.colorBorderTheme] = true;
    this.classObject['mainThemeButton'] = this.theme === 'main';
    this.classObject['darkThemeButton'] = this.theme === 'dark'
    this.classObject['selected'] = this.selected;

    this.classColorIcon['blackColorTheme'] = this.colorIconTheme === 'black';
    this.classColorIcon['mainColorTheme'] = this.colorIconTheme === 'main';

  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach(param => {
      this.classObject[param] = changes[param].currentValue
    })
  }

  clickButton() {
		this.onClick.emit();
	}

}
