export interface Container {
  id: number
  name: string
  type: string
  ucloudIdentifier: string
  api_key?: string
  cloudId: number
}

export interface ScheduledJobs {
  id: string
  contractUuid: string
  name: string
  description?: string
  cron_expression?: string
  appointmentDate?: Date
  period: number
  metadata?: any
  status: ScheduledJobStatus
  type: ScheduledJobType
  created_at: Date
  updated_at: Date
}

const EnumScheduledJobStatus = {
  Pending: 'Pending',
  Running: 'Running',
  Completed: 'Completed',
  Failed: 'Failed'
}

const EnumScheduledJobType = {
  CompareClouds: 'CompareClouds',
  Email: 'Email',
  SMS: 'SMS',
  PushNotification: 'PushNotification',
  PriceList: 'PriceList',
  SummarizedVmPerformance: 'SummarizedVmPerformance',
  SummarizedBillingHistory: 'SummarizedBillingHistory'
}

export type ScheduledJobStatus = keyof typeof EnumScheduledJobStatus
export type ScheduledJobType = keyof typeof EnumScheduledJobType

export interface CreateSchedulerJobDTO extends Omit<ScheduledJobs, 'id' | 'created_at' | 'updated_at'> {}
export interface SchedulerJobDTO extends Partial<ScheduledJobs> {}

export enum Clouds {
  AWS = "AWS",
  AZURE = "AZURE",
  GCP = "GCP",
  IBM = "IBM",
}

export type Logs = {
  cloud: Clouds,
  message: string,
  isError?: boolean,
  date?: Date,
}
