import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { TwoColumnsTableComponent } from './two-columns-table/two-columns-table.component';
import { AppTranslationModule } from '../../components/app.translation.module';
import { NbTooltipModule } from '@nebular/theme';
import { UniqueListComponent } from './unique-list/unique-list.component';
import { UniqueListItemComponent } from './unique-list/unique-list-item/unique-list-item.component';

@NgModule({
  declarations: [TwoColumnsTableComponent, UniqueListComponent, UniqueListItemComponent],
  imports: [
    CommonModule,
    ChartsModule,
    AppTranslationModule,
    NbTooltipModule
  ],
  exports: [
    TwoColumnsTableComponent,UniqueListItemComponent, UniqueListComponent, CommonModule
  ]
})
export class TableUsavingsModule { }
