import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { Router } from '@angular/router';
import { AppSettingsService } from './app-settings.service';
import { RequestResult } from '../../@core/models/request-result';
import { UserDataProps } from '../../@core/models/headerModel';
import { UserService } from '../../@core/services/user.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'nb-auth',
  styleUrls: ['./app-settings-layout.component.scss'],
  template: `
    <nb-layout>
      <nb-layout-column>
        <nav class="navigation">
          <div class="back-button">
            <a href="#" (click)="back()" class="link back-link" aria-label="Back">
              <i class="fas fa-chevron-left"></i>
            </a>
            <span i18n="@@settings">Settings</span>
          </div>
          <a class="reload-button" data-cy="reload-button" style="float: right;">
            <button style="cursor:pointer !important; padding: 12px; font-size: 0.8rem; margin-right: 16px" nbButton status="info" (click)="reload()">
            <i class="fas fa-sync"></i>
            </button>
          </a>
        </nav>

        <router-outlet></router-outlet>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class AppSettingsLayoutComponent implements OnDestroy {

  private alive = true;

  subscription: any;

  userId: number
  authenticated: boolean = false;
  token: string = '';

  private unsubscription = new Subject() 
  // showcase of how to use the onAuthenticationChange method
  constructor(
    protected location: Location,
    protected router: Router,
    private appService: AppSettingsService,
    private userService: UserService) {
      this.userService.getUser().takeUntil(this.unsubscription).subscribe(user => {
        if (user) {
          this.userId = user.userId;
        }
      })
  }

  async reload() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => { return false };
    let currentUrl = `${this.router.url}?`;

    await this.router.navigateByUrl(currentUrl);
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  async back() {
    await this.getActivityClouds();

    this.location.back();
    return false;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  async getActivityClouds() {

    this.appService.findAllClouds(true,this.userId).subscribe((res: RequestResult) => {
      if (res.isSuccess()) {
        sessionStorage.setItem('clouds', JSON.stringify(res.getResponse()));
      }
    });
  }
}
