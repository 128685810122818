import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const fadeAnimation = (enterAnimate: number = 200, leaveAnimate: number = 300) => {
  return trigger("simpleFadeAnimation", [
    state("in", style({ opacity: 1 })),
    transition(":enter", [style({ opacity: 0 }), animate(enterAnimate)]),
    transition(":leave", animate(leaveAnimate, style({ opacity: 0 }))),
  ]);
}

export const simpleFadeAnimation = fadeAnimation(200, 300);

export const toggleClickAnimation = trigger("toggleClickAnimation", [
  state(
    "in",
    style({
      opacity: 1,
      transform: "translateX(0)",
    })
  ),
  transition(":enter", [
    style({
      opacity: 0,
      transform: "translateX(-100%)",
    }),
    animate(200),
  ]),
  transition(":leave", animate(200, style({ opacity: 0 }))),
]);

export const animImageSlider = trigger("animImageSlider", [
  state('list', style({ transform: 'translateX(0)' })),
  state('detail', style({ transform: 'translateX(-50%)' })),
  transition('* => *', animate(300))
]);
