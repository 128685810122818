import { TranslateService } from "@ngx-translate/core";
import IConfigurationChart from "./Interface/IConfigurationChart";
import { ChartOptions,Chart, PositionType } from "chart.js"

export default class ChartConfigure {

    optionsConfigure: ChartOptions;

    constructor(private showAxis = true,private XaxisLabel: string = undefined,
      private YaxisLabel: string = undefined, private legend: boolean, private legendPosition: PositionType,private tooltip: (tooltipItem, data) => string = undefined ){
      this.optionsConfigure = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {display: this.legend,position: this.legendPosition},
          scales: {
              xAxes: [
                {
                  display: this.showAxis,
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: false,
                    labelString: ''
                  },
                  ticks: {
                    // display: this.showAxis,
                    beginAtZero: true
                  }
                }
              ],
              yAxes: [
                {
                  display: this.showAxis,
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: false,
                    labelString: ''
                  },
                  ticks: {
                    // display: this.showAxis,
                    beginAtZero: true
                  }
                }
              ],
          }
      };

      if (this.tooltip !== undefined) {
        this.optionsConfigure.tooltips = {
          callbacks: {
            label: tooltip
          }
        }
      }

      if (this.XaxisLabel ){
        this.setAxisNameLabelX(XaxisLabel)
      }

      if (this.YaxisLabel){
        this.setAxisNameLabelY(YaxisLabel)
      }
    }

    getDefaultColor(){
      return localStorage.getItem('ucloud_client_color_theme');
    }

    getRandomColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`
    }

    setReponsive(value: boolean){
        this.optionsConfigure.responsive = value
    }

    activeAspectRatio(value: boolean){
        this.optionsConfigure.maintainAspectRatio = value
    }

    setAxisNameLabelX(label: string,datasetIndex: number=0){

        this.optionsConfigure.scales.xAxes[datasetIndex].scaleLabel = {
          display: true,
          labelString: label
        }
    }

    setAxisNameLabelY(label: string,datasetIndex: number=0){

        this.optionsConfigure.scales.yAxes[datasetIndex].scaleLabel = {
          display: true,
          labelString: label 
        }
    }

    setAxisXStartsZero(begin: boolean,datasetIndex: number){

        this.optionsConfigure.scales.xAxes[datasetIndex] = {
          ticks: {
            beginAtZero: begin
          }
        }
    }

    setAxisYStartsZero(begin: boolean,datasetIndex: number){

        this.optionsConfigure.scales.yAxes[datasetIndex] = {
          ticks: {
            beginAtZero: begin
          }
        }
    }

    setAspectRatio(value: number){
      this.optionsConfigure.aspectRatio = value
    }

    getLegend() {
      this.optionsConfigure.legend.display = true;
    }

    setMultiAxis(){
      this.optionsConfigure.scales.yAxes = [
        {
          id: 'y1',
          position: 'left',
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: false,
            labelString: ''
          },
          ticks: {
            beginAtZero: true
          }
        },
        {
          id: 'y2',
          position: 'right',
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: false,
            labelString: ''
          },
          ticks: {
            beginAtZero: true
          }
        },
      ]
    }


}