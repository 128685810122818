import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppTranslationModule } from '../components/app.translation.module';
import { AwsIconsModule } from './aws-icons/aws-icons.module';
import { ButtonsModule } from './buttons/buttons.module';
import { CardsModule } from './cards/cards.module';
import { ChartsUsavingsModule } from './charts-usavings/charts-usavings.module';
import { ErrorModule } from './error/error.module';
import { UsavingsListModule } from './list/usavings-list.module';
import { LoadingModule } from './loading/loading.module';
import { TableUsavingsModule } from './table-usavings/table-usavings.module';
import { UtilsModule } from './utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    CardsModule,
    LoadingModule,
    AppTranslationModule,
    UsavingsListModule,
    AwsIconsModule,
    UtilsModule,
    ButtonsModule,
    ErrorModule,
    ChartsUsavingsModule,
    TableUsavingsModule
  ],
  exports: [
    CardsModule,
    AwsIconsModule,
    LoadingModule,
    UsavingsListModule,
    UtilsModule,
    ButtonsModule,
    ErrorModule,
    ChartsUsavingsModule,
    TableUsavingsModule
  ],
  declarations: [],
})
export class SharedModule { }
