import { ChartData, ChartDataSets, ChartOptions } from "chart.js"

export default class DatasetConfigure {

    datasetConfigure: ChartData = {
        datasets: [],
        labels: []
    };

    type;

    constructor(
        data: number[][],
        labels: string[], 
        type: string,
        dataNick: string[] | null = null,
        palette: string = undefined,
        color:  (string | string[])[] = undefined,
        borderColor:  (string | string[])[] = undefined,
        additionalDatasetConfig: ChartDataSets = {} as ChartDataSets
        ) {            
            try{

                this.type = type;
                if (dataNick && data.length !== dataNick.length){
                    throw new Error("Data Nickname doesn't match with number of data")
                }
                const coolors = color ? this.getPaletteColors(palette,color,data.length,data[0].length) : new Array(data.length).fill(this.getDefaultColor()) 

                let coolorsBorder;
                if (type === "line"){
                    coolorsBorder = color ? this.getPaletteColors(palette,color,data.length,data[0].length) : new Array(data.length).fill(localStorage.getItem('ucloud_client_color_theme'))
                } else {
                    coolorsBorder = borderColor ? this.getPaletteColors(palette,borderColor,data.length,data[0].length) : new Array(data.length).fill(["pie","doughnut"].includes(type) ? 'white' : this.getDefaultColor())
                }
                
                // const coolors = ['red', 'blue']
                this.datasetConfigure.datasets = data.map((listValues,index) => {
                    
                    return {
                        data: listValues,
                        label: dataNick ? dataNick[index] : undefined,
                        type: type,
                        backgroundColor: coolors[index],
                        borderColor: coolorsBorder[index],
                        fill: 'origin',
                        ...additionalDatasetConfig
                        // pointBackgroundColor: coolors[index],
                        // pointBorderColor: coolors[index]
                    }
                })
                this.datasetConfigure.labels = labels
            } catch (error){
                console.log('ERROR',error)
            }
        
    }

    getPaletteColors(palette: string | undefined,colorLabel?: (string | string[])[],numberDataset?: number,numberValues?: number): string[][]{
        if (palette){
            // TODO

        } else {
            let colors;
            // string[]
            if (typeof colorLabel === "object"){
                if (colorLabel.length !== numberDataset) {
                    throw new Error("Number colors doesn't match with amount datasets.")
                }
                // string[][]
                colors = Array(numberDataset).fill(null).map((_,index) => {
                    

                    if (!colorLabel[index]){
                        return this.getRandomColor()
                    } else if( colorLabel[index] === 'random'){
                        return Array(numberValues).fill(0).map(_ => this.getRandomColor())
                    } else if (typeof colorLabel[index] === "object" ){
                        let localColors = colorLabel[index] as string[]

                        if (localColors.length !== numberValues ){
                            localColors = localColors.concat(new Array(numberValues - localColors.length).fill("gray"))
                        }

                        return localColors
                    } 
                    else {
                        return colorLabel[index]
                    }

                });
                  
            } else if (typeof colorLabel === "string"){
                if (numberDataset !== 1) {
                    throw new Error("Number colors doesn't match with amount datasets.")
                }

                colors = [colorLabel];
            } else {
                colors = new Array(numberDataset).fill(null).map(_ => Array(numberValues).fill(0).map(_ => this.getRandomColor()))
            }
            return colors
        }

    }

    getDefaultColor(){
      return localStorage.getItem('ucloud_client_color_theme');
    }

    getLightColor(){
        return localStorage.getItem('ucloud_client_color_theme_rgba_lighten');
      }

    getRandomColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`
    }

    setColorDataset(index: number,color: string){
        this.datasetConfigure.datasets[index].backgroundColor = color;
        this.datasetConfigure.datasets[index].borderColor = color;
    }



}