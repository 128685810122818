import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class StateComponentService {

  loading = new BehaviorSubject(false);
  errorRequest = new BehaviorSubject(false);

  constructor() { }

  getLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  getHasError(): Observable<boolean> {
    return this.errorRequest.asObservable()
  }

  startLoading(): void{
    this.loading.next(true)
  }

  stopLoading(): void{
    this.loading.next(false)
  }

  errorStatus(value: boolean) {
    this.errorRequest.next(value);
  }

  reset() {
    this.startLoading();
    this.errorStatus(false);
  }

}
