import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultCardUsavingsComponent } from './components/default-card-usavings/default-card-usavings.component';
import { AppTranslationModule } from '../../components/app.translation.module';
import { LoadingModule } from '../loading/loading.module';
import { CardDefaultIconComponent } from './components/default-card-usavings/components/card-default-icon/card-default-icon.component';
import { CardDefaultInfoComponent } from './components/default-card-usavings/components/card-default-info/card-default-info.component';
import { NbTooltipModule } from '@nebular/theme';

const COMPONENTS = [
  DefaultCardUsavingsComponent,
  CardDefaultIconComponent,
  CardDefaultInfoComponent
]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    AppTranslationModule,
    LoadingModule,
    NbTooltipModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class CardsModule { }
