import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf('/users/login') != -1) {

      request = request.clone({
        responseType: 'text'
      });

      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {

          if (event instanceof HttpResponse) {

            if(event.body != null){
            var xx = event.clone({
              body: { token: event.body },
            });
            return xx;
            }
            
          }
        }));

    }else   
    return next.handle(request);
    
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

}

