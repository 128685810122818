import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeMXN from '@angular/common/locales/es-MX';
import localeEU from '@angular/common/locales/eu';
import { APP_INITIALIZER, NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CoreModule } from './@core/core.module';
import { RequestInterceptor } from './@core/interceptors/http-interceptor';
import { noAuthInterceptor } from './@core/interceptors/noAuthInterceptor';
import { ConfigService, loadConfiguration, loadExchanged } from './@core/services/config.service';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth-guard.service';
import { AppTranslationModule } from './components/app.translation.module';
import { UsavingsComponentsModule } from './components/usavings-components.module';
import { PipesModule } from './pipes/pipes.module';
import { HttpQueueService } from './queue/http-queue.service';
import { defineConfigurationRadiusChartjs } from './@core/utils/radiusBarChart';
let get_dir_lang = window.location.href.replace("https://", "").replace("http://", "").split("/")[1];

let t_path = (window.clientInformation && window.clientInformation.language) || window.navigator.language || get_dir_lang || "en";
  if(t_path.includes("es") || get_dir_lang == "es") t_path = "es";
  else t_path = "en";
const translations = require(`raw-loader!../locale/messages.en.xlf`);

registerLocaleData(localeEU);
registerLocaleData(localeMXN);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ThemeModule.forRoot(),
    UsavingsComponentsModule,
    CoreModule.forRoot(), // Aqui está a configuração da estratégia de auth
    AppTranslationModule,
    PipesModule.forRoot(),
    Ng2SearchPipeModule
  ],
  bootstrap: [AppComponent],
  exports: [UsavingsComponentsModule],
  providers: [
    HttpQueueService,
    AuthGuard,
    I18n,
    ConfigService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: APP_INITIALIZER, useFactory: loadConfiguration, deps: [ConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: loadExchanged, deps: [ConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: noAuthInterceptor, multi: true },
    { provide: TRANSLATIONS, useValue: translations },
    { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
  ],
})
export class AppModule {
  constructor(private translate: AppTranslationModule, private configService: ConfigService) {
    const currentLang = this.translate.getLanguageSession();
    this.configService.sendLang(currentLang);

    defineConfigurationRadiusChartjs();
  }
}
