import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'two-columns-table',
  templateUrl: './two-columns-table.component.html',
  styleUrls: ['./two-columns-table.component.scss']
})
export class TwoColumnsTableComponent implements OnInit {

  @Input() columns

  @Input() alignFirstColumn = 'start';
  @Input() alignSecondColumn = 'start';

  @Input() rowContent: {col1: string | number; col2: string | number}[]

  constructor() { }

  ngOnInit() {
    if (this.columns.length !== 2) {
      throw new Error("Number of columns not equal 2.")
    }
  }

}
