import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ThemeService } from './@core/services/theme.service';
import { AnalyticsService } from './@core/utils/analytics.service';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService, @Inject(DOCUMENT) private _document: HTMLDocument, private themeService: ThemeService) {
    this.setAppFavicon(this.themeService.getFaviconPath());
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
  }

  public setAppFavicon(path: string) {
    const favicon = this._document.getElementById('favicon');
    favicon.setAttribute('href', path);
  }
}
