import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef , NbToastrService } from '@nebular/theme';
import { AppTranslationModule } from '../../../../components/app.translation.module';
import { SelectSearchComponent } from '../../../../components/select-search/select-search.component';
import Contract from '../../../../@core/entities/Contract';
import GlobalDataUsavings from '../../../../@core/interfaces/GlobalDataUsavings.interface';

interface configPaginate {
  itemsPerPage: number,
  currentPage: number,
  totalItems: number
}
@Component({
  selector: 'modal-change-role',
  templateUrl: './modal-change-role.component.html',
  styleUrls: ['./modal-change-role.component.scss']
})
export class ModalChangeRoleComponent implements OnInit {

  @Input() contracts: GlobalDataUsavings<Contract[]>;
  @Input() currentContract: any;

  constructor(protected ref: NbDialogRef<ModalChangeRoleComponent>,
    private toastService: NbToastrService,
    private translate: AppTranslationModule) { }

  config: configPaginate;
  contractToFilter = [];
  searchInput: any;
  contractQnt: any;

  ngOnInit() {
    this.contractToFilter = this.contracts.data;
    this.config = {
      itemsPerPage: 7,
      currentPage: 1,
      totalItems: this.contracts.data.length
    };
  }

  search() { this.searchContracts(); }

  searchContracts(){
    if (this.searchInput.length) {
      this.contractToFilter = this.contracts.data.filter((item) => {
        return item.name.toLowerCase().includes(this.searchInput.toLowerCase());
      })
    } else this.contractToFilter = this.contracts.data;
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
  }

  close(contract: Contract) {
    if (contract) {
      this.ref.close(contract);
    } else {
      this.ref.close()
    }
  }
}
