import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NbAuthResult, NbAuthStrategyClass, NbPasswordAuthStrategy, NbPasswordAuthStrategyOptions } from "@nebular/auth";
import { Observable } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from "../@core/services/config.service";

@Injectable({
  providedIn: 'root'
})
export class UsAuthStrategy extends NbPasswordAuthStrategy {
    constructor(private configService: ConfigService, http: HttpClient, route: ActivatedRoute) {
        super(http, route);
    }

    static setup(options: NbPasswordAuthStrategyOptions): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
      return [UsAuthStrategy, options];
    }

    authenticate(data?: any): Observable<NbAuthResult> {
        const module = 'login';
        const method = this.getOption(`${module}.method`);
        const url = this.configService.getUrlUsavings(this.getActionEndpoint(module));
        const requireValidToken = this.getOption(`${module}.requireValidToken`);
        return this.http.request(method, url, {body: data, observe: 'response'})
          .pipe(
            map((res) => {
              if (this.getOption(`${module}.alwaysFail`)) {
                throw this.createFailResponse(data);
              }
              return res;
            }),
            map((res) => {
              return new NbAuthResult(
                true,
                res,
                this.getOption(`${module}.redirect.success`),
                [],
                this.getOption('messages.getter')(module, res, this.options),
                this.createToken(this.getOption('token.getter')(module, res, this.options), requireValidToken));
            }),
            catchError((res) => {
              return this.handleResponseError(res, module);
            }),
          );
      }
}
