import { Component, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { EventEmitter } from 'events';
import { CloudsModal } from '../../../../@core/models/cloudsModal';
import { CustomCloudsModal } from '../../../../@core/models/customCloudsModal';
import { RequestResult } from '../../../../@core/models/request-result';
import { AppSettingsService } from '../../app-settings.service';
import { AppTranslationModule } from '../../../app.translation.module';

@Component({
  selector: 'custom-cloud-delete-modal',
  templateUrl: './custom-cloud-delete-modal.component.html',
  styleUrls: ['./custom-cloud-delete-modal.component.scss']
})
export class CustomCloudDeleteModalComponent implements OnInit {

  constructor(protected ref: NbDialogRef<CustomCloudDeleteModalComponent>,
    private translate: AppTranslationModule) { }

  ngOnInit() {
  }
  
  @Input() cloud: CloudsModal;

  deleteCloud() {
    this.ref.close(true);
  }
  
  dismiss() {
    this.ref.close(false);
  }
}
