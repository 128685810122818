import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'elastic-load-balancer',
  templateUrl: './elastic-load-balancer.component.html',
  styleUrls: ['./elastic-load-balancer.component.scss']
})
export class ElasticLoadBalancerComponent implements OnInit {

  @Input() size = 32;
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

}
