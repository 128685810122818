import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../@core/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsGuard implements CanActivate {
  constructor(private userService: UserService) {}
  canActivate(){
    let hasUserPermission: any = sessionStorage.getItem('userData');
    hasUserPermission = JSON.parse(hasUserPermission).hasOwnProperty('isAdmin');
    if(hasUserPermission) return true;
    else return false;
  }
}
