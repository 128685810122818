import { FlavorModal } from "./flavorModal";

export class CloudsModal {
  public name: string;
  public created_at?: string;
  public isActive?: boolean;
  public flavors?: Array<FlavorModal>;
  public contract?: string;
  public uuid?: string;
  public contractUuid: string;
  public userId: number;
  public typeImport?: "normal" | "discount";
  public id?: any;
  public paginateInfo?: {
    total: number
    perPage: number
    current: number
    lastPage: number
  }

  constructor(data: any) {
    this.name = data.name;
    this.flavors = data.data;
    this.contractUuid = data.contractUuid;
    this.userId = data.userId;
    this.typeImport = data.typeImport;
    this.id = data.id;
    this.paginateInfo = data.paginateInfo
  }
}
