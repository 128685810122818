import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { NbThemeService } from '@nebular/theme';


@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    private themeSubscription: any;

    private images: any = {
        login: null,
        sideMenu: null,
        favicon: './favicon.ico'
    };

    constructor(private http: HttpClient, private config: ConfigService, private nbThemeService: NbThemeService) {
        this.themeSubscription = this.nbThemeService.getJsTheme().subscribe(config => {
            this.images = config.variables.images;
        });
        this.nbThemeService.changeTheme(this.config.getTheme() || 'default');
    }

    public getClientName(): string {
        return this.config.getClientName();
    }

    public getSystemName(): string {
        return this.config.getSystemName();
    }

    public getLogoPath(): string {
        return this.images.login;
    }

    public getSideMenuLogoPath(): string {
        return this.images.sideMenu;
    }

    public getSideMenuClientLogoPath(): string {
        let clientName = this.config.getClientName();
        if ((clientName == undefined && clientName.trim().length) || clientName == '') {
          clientName = 'ucloud';
        }
        return `./assets/clients/${clientName}/logo.png`;
    }

    public getFaviconPath(): string {
        return this.images.favicon;
    }
}
