export const SERPRO_THEME = {
  name: 'serpro',
  base: 'default', //herda as variáveis do default
  variables: {

    images: {
      login: '../../../assets/logos/serpro/login-serpro.png', // se não houver logo, deixar nulo
      sideMenu: '../../../../assets/logos/serpro/menu-serpro.png', // se não houver logo, deixar nulo
      favicon: '../assets/logos/serpro/favicon.ico' // ./favicon.ico é o default
    },

  },
};
