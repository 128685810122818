import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export class PendingRequest {
  url: string;
  method: string;
  options: any;
  body: any;
  subscription: Subject<any>;

  constructor(url: string, method: string, options: any, body: any,subscription: Subject<any>) {
    this.url = url;
    this.method = method;
    this.options = options;
    this.body = body;
    this.subscription = subscription;
  }
}

@Injectable()
export class HttpQueueService {
  private opennedConnections = 0;
  private requests$ = new Subject<any>();
  private queue: PendingRequest[] = [];
  private maxRequests: number = 20
  private cancelRequests = new Subject();

  constructor(private httpClient: HttpClient) {
    this.requests$.subscribe(request => this.execute(request));
  }

  //? Call this method to add your http request to queue
  invoke(url, method, params = '', options, body = '', maxRequests = 20) {
    this.maxRequests = maxRequests;
    return this.addRequestToQueue(url, method, params, options, body);
  }

  // pending = [req4, req5]
  // executing = [req1, req2, req3]
  // oc = 3

  private execute(requestData) {
    if(requestData.method == "GET"){
      this.httpClient.get(requestData.url, requestData.options).takeUntil(this.cancelRequests)
        .subscribe(res => {
          const sub = requestData.subscription;
          sub.next(res);
          this.opennedConnections--;
          this.checkOppenedConnections();
        },
        err => {
          const sub = requestData.subscription;
          sub.next(err);
          this.opennedConnections--;
          this.checkOppenedConnections();
        });
    } else if (requestData.method == "POST"){
      this.httpClient.post(requestData.url, requestData.body, requestData.options).takeUntil(this.cancelRequests)
        .subscribe(res => {
          const sub = requestData.subscription;
          sub.next(res);
          this.opennedConnections--;
          this.checkOppenedConnections()
        });
    } else if (requestData.method == "PUT"){
      this.httpClient.put(requestData.url, requestData.body, requestData.options).takeUntil(this.cancelRequests)
        .subscribe(res => {
          const sub = requestData.subscription;
          sub.next(res);
          this.opennedConnections--;
          this.checkOppenedConnections()
        });
    } else if (requestData.method == "PATCH"){
      this.httpClient.patch(requestData.url, requestData.options).takeUntil(this.cancelRequests)
        .subscribe(res => {
          const sub = requestData.subscription;
          sub.next(res);
          this.opennedConnections--;
          this.checkOppenedConnections()
        });
    } else {
      this.httpClient.delete(requestData.url, requestData.options).takeUntil(this.cancelRequests)
        .subscribe(res => {
          const sub = requestData.subscription;
          sub.next(res);
          this.opennedConnections--;
          this.checkOppenedConnections()
        });
    }
  }

  private addRequestToQueue(url, method, params, options, body) {
    const sub = new Subject<any>();
    const request = new PendingRequest(url, method, options, body, sub);
    this.queue.push(request);
    this.checkOppenedConnections();
    return sub;
  }

  private startNextRequest() {
    if (this.queue.length > 0) {
      this.opennedConnections++;
      this.execute(this.queue.shift());
    }
  }

  resetQueue() {
    this.queue = [];
    this.cancelRequests.next();
    this.opennedConnections = 0;
  }

  checkOppenedConnections() {
    if (this.opennedConnections < this.maxRequests) {
      this.startNextRequest();
    }
  }
}
