import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfigService } from '../@core/services/config.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

const translationOptions = {
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient]
  }
};

@NgModule({
  imports: [TranslateModule.forRoot(translationOptions)],
  exports: [TranslateModule],
  providers: [TranslateService]
})
export class AppTranslationModule {
  private language: string = null;

  constructor(public translate: TranslateService) {
    translate.setDefaultLang('en');

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      translate.use(event.lang);
      translate.setDefaultLang(event.lang);
      this.language = event.lang;
    });

    ConfigService.changeLang.subscribe((lang) => this.setLanguage(lang));

    if (this.language !== null) {
      translate.use(this.language);
    }
  }

  setLanguage(lang = 'en'): string {
    this.language = lang;
    this.translate.use(this.language);
    return this.language;
  }

  getLanguage() {
    return this.language;
  }

  getLanguageSession() {
    let currentLang = sessionStorage.getItem('lang');
    if (currentLang == null) {
      currentLang = 'en';
      sessionStorage.setItem('lang', currentLang);
    }
    return currentLang;
  }

  async getTranslation(keyWord: string): Promise<string> {
    return await this.translate.get(keyWord).toPromise();
  }

  instant(key: string, alias?: string): string {
    if (alias) {
      return this.translate.instant(alias + '.' + key);
    }
    return this.translate.instant(key);
  }
}
