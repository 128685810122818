import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { NbDialogService, NbMenuService, NbSidebarService, NbToastrService } from '@nebular/theme';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ConfigService } from '../../../@core/services/config.service';
import { ContainerService } from '../../../@core/services/container.service';
import { DashboardService } from '../../../@core/services/dashboard.service';
import { ThemeService } from '../../../@core/services/theme.service';
import { UserService } from '../../../@core/services/user.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { AppTranslationModule } from '../../../components/app.translation.module';
import { ModalChangeRoleComponent } from './modal-change-role/modal-change-role.component';
import { ContractOptions, ContractProps, UserDataProps } from '../../../@core/models/headerModel';
import { ContractService } from '../../../@core/services/contract.service';
import IContract from '../../../@core/interfaces/contract.interface';
import { Observable, Subject } from 'rxjs';
import { IUser } from '../../../@core/interfaces/user.interface';
import Contract from '../../../@core/entities/Contract';
import { last, take, takeLast, takeUntil } from 'rxjs/operators';
import GlobalDataUsavings from '../../../@core/interfaces/GlobalDataUsavings.interface';
import { StatusData } from '../../../@core/enum/EnumStatusData';
import Container from '../../../@core/entities/Container';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),

      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ],
  providers: [DashboardService]
})
export class HeaderComponent implements OnInit, OnDestroy {

  CLIENT_NAME: string;
  SYSTEM_NAME: string;

  @Input() position = 'normal';
  login: string;
  changeLanguage: any = [{ title: "ES" }, { title: "EN" }, { title: "PT-BR" }];
  companiesOptions: ContractOptions[] = [];
  compactSideMenu: boolean = false;
  userHasPermission: boolean = false;
  userCompanies: ContractProps[] = [];
  selectedCompanie: string = "";
  hasCompanies: boolean = false;

  contracts: GlobalDataUsavings<Contract[]>;
  currentContract: GlobalDataUsavings<Contract>;
  containers: GlobalDataUsavings<Container[]> = {data: [], status: StatusData.EMPTY}
  currentContainer: GlobalDataUsavings<Container> = { data: undefined, status: StatusData.EMPTY};
  user: IUser;
  private unsubscription = new Subject();

  constructor(
    private sidebarService: NbSidebarService,
    private userService: UserService,
    private menuService: NbMenuService,
    private Router: Router,
    private analyticsService: AnalyticsService,
    private themeService: ThemeService,
    private i18n: I18n,
    private translate: AppTranslationModule,
    private configService: ConfigService,
    private containerService: ContainerService,
    private dashboardService: DashboardService,
    private dialogService: NbDialogService,
    private toastService: NbToastrService,
    private contractService: ContractService,
  ) {
    this.login = sessionStorage.getItem(`userName`);
    this.userService.getUser().pipe(takeUntil(this.unsubscription)).subscribe( user => this.user = user)
    this.contractService.getContracts().pipe(takeUntil(this.unsubscription)).subscribe( contracts => this.contracts = contracts)
    this.contractService.getCurrentContract().pipe(takeUntil(this.unsubscription)).subscribe( currenctContract => this.currentContract = currenctContract)
    this.containerService.getContainers().pipe(takeUntil(this.unsubscription)).subscribe(containers => {
      if (containers) {
        this.containers = containers
      }
    })

    this.containerService.getCurrentContainer().pipe(takeUntil(this.unsubscription)).subscribe(container => {
      if (container) {
        this.currentContainer = container
      }
    })
  }

  ngOnDestroy(): void {
      this.unsubscription.next()
  }  

  async ngOnInit() {

    const menuServiceObs = this.menuService.onItemClick().pipe().subscribe(async el => {
      await this.headerItemClick(el);
      menuServiceObs.unsubscribe();
    });

    this.CLIENT_NAME = this.themeService.getClientName();
    this.SYSTEM_NAME = this.themeService.getSystemName();

  }


  get hasError() {
    return this.containers.status === StatusData.ERROR || this.currentContainer.status === StatusData.ERROR
  }

  get hasContract() {
    return this.currentContract.status === StatusData.READY
  }

  get hasDataCurrentContainer() {
    return this.currentContainer.status === StatusData.READY
  }

  get currentContainerIsEmpty() {
    return this.currentContainer.status === StatusData.EMPTY
  }

  get loadingContainers() {
    return this.containers.status === StatusData.LOADING
  }

  get loadingCurrentContainer() {
    return this.currentContainer.status === StatusData.LOADING
  }

  async headerItemClick(el: any) {
    if (['ES', 'EN', 'PT-BR'].includes(el.item.title)) {
      const lang = el.item.title.toLowerCase();
      if (lang == 'en' || lang == 'es' || 'pt-br') {
        sessionStorage.setItem('lang', lang);
        this.configService.sendLang(lang);
        this.callActualRoute();
      }
    } 
  }

  async changeContract(contract: GlobalDataUsavings<Contract>) {

    this.contractService.setCurrentContract(contract,true)

    await this.callActualRoute();
    this.toastService.success(await this.translate.getTranslation("Success in changing contracts"), await this.translate.getTranslation("Success"));
  }

  async callActualRoute() {
    return new Promise<void>(async (resolve) => {
      this.Router.routeReuseStrategy.shouldReuseRoute = () => { return false };
      let currentUrl = `${this.Router.url}?`;

      await this.Router.navigateByUrl(currentUrl);
      this.Router.navigated = false;
      this.Router.navigate([this.Router.url]);
      resolve();
    });
  }

  changeContainer(container: Container) {
    const statusContainer = {
      data:container,
      status: StatusData.READY
    }
    this.containerService.setCurrentContainer(statusContainer)
    this.containerService.saveCurrentContainerOnSessionStorage(statusContainer)
  }

  toggleSidebar(): boolean {
    this.compactSideMenu = !this.compactSideMenu;
    this.sidebarService.toggle(this.compactSideMenu, 'menu-sidebar');
    return false;
  }

  moveToSettings() {
    this.Router.navigateByUrl('/settings');
  }

  logout() {
    this.Router.navigateByUrl('auth/logout');
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  getCompaniesUser() {
    return new Promise<void>(async (resolve) => {
      this.userCompanies = await this.dashboardService.getContracts();
      this.hasCompanies = true;
      resolve();
    })
  }

  openModal() {
    const modalData: any = {
      context: {
        contracts: this.contracts,
        currentContract: this.currentContract.data.name
      }
    }

    this.dialogService.open(ModalChangeRoleComponent, modalData).onClose.subscribe(async (res: Contract| undefined) => {
      if (res && res.name !== this.currentContract.data.name) {
        this.changeContract({
          data: res,
          status: StatusData.READY
        });
      }
    })
  }

}
