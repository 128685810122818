import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { AppSettingsComponent } from './app-settings.component';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../@core/services/config.service';
import { take, map, catchError, filter } from 'rxjs/operators';
import { RequestResult } from '../../@core/models/request-result';
import { CloudsModal } from '../../@core/models/cloudsModal';
import { listCloudModal } from '../../@core/models/listCloudModel';
import { Observable, of as observableOf } from 'rxjs';
import { HttpQueueService } from '../../queue/http-queue.service';

@Injectable()
export class AppSettingsService {

  constructor(private http: HttpClient, private config: ConfigService,private httpQueue: HttpQueueService) { }

  listClouds(params: listCloudModal = { showAll: false, showFlavors: false }, userId: any = '') {
    return this.http.get(this.config.getUrlUsavings(`/cloud?showAll=${params.showAll}&showFlavors=${params.showFlavors}&userId=${userId}`), this.config.getHttpOptions())
      .pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.config.handleError(err)));
  }

  getFlavorsClouds( cloudId: number,page: number, offset: number = 10,sortColumn: string | undefined, direction: string,filterName: string): Observable<any> {
    let headersObj = this.config.getHttpOptions({
      offset: offset
    });

    let url = `/cloud/flavors?cloudId=${cloudId}&page=${page}`;

    if (sortColumn) {
      url += `&sort=${sortColumn}&direction=${direction}`
    }

    if (filterName !== "") {
      url += `&name=${filterName}`
    }

    return this.httpQueue.invoke(this.config.getUrlUsavings(url), 'GET', '' , headersObj, "",10)
    .pipe(
      take(1),
      map(x => new RequestResult(true, x)),
      catchError((err) => {
        let errorHandled: any = this.config.handleErrorResquestResult(err)
        return observableOf(errorHandled);
      }),
    );
  }

  findAllClouds(showAll: boolean, userId: any = '') {
    return this.http.get(this.config.getUrlUsavings(`/cloud?showAll=${showAll}&userId=${userId}`), this.config.getHttpOptions())
      .pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.config.handleError(err)));
  }

  updateClouds(object) {
    return this.http.put(this.config.getUrlUsavings(`/cloud`), object, this.config.getHttpOptions()).pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.config.handleError(err)));
  }

  importCloud(object) {
    return this.http.post(this.config.getUrlUsavings(`/cloud/import`), object, this.config.getHttpOptions()).pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.config.handleError(err)));
  }

  importPrivateCloud(object) {
    return this.http.post(this.config.getUrlUsavings(`/cloud/private`), object, this.config.getHttpOptions()).pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.config.handleError(err)));
  }

    importCloudCustom(object) {
    return this.http.post(this.config.getUrlUsavings(`/cloud/custom`), object, this.config.getHttpOptions()).pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.config.handleError(err)));
  }
  deleteCloud(cloudName,contractUuid,userId) {
    return this.http.delete(this.config.getUrlUsavings(`/cloud/${cloudName}`), this.config.getHttpOptions({
      'cloud': cloudName,
      'contractUuid': contractUuid,
      'userId': userId.toString()
    })).pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.config.handleError(err)));
  }
}
