import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, PageEvent, MatSort } from "@angular/material";
import { AppSettingsService } from '../../app-settings.service';
import { catchError, map, startWith, switchMap, takeUntil, } from 'rxjs/operators';
import { Observable, Subject, of as observableOf } from 'rxjs';
import { simpleFadeAnimation } from '../../../../animations';
import { AppTranslationModule } from '../../../app.translation.module';
import { ContractService } from '../../../../@core/services/contract.service';

interface ICloud {
  id: number
  name: string
  user_id: number
  isActive: boolean
  version: number
  createdAt: string
  updatedAt: string
  flavors?: IFlavor[]
  paginateInfo: {
    total: number
    perPage: number
    current: number
    lastPage: number} | null
}

interface IFlavor {
  id: number
  name: string
  vCPU: number  
  memory: number
  osType: string
  price: number
  reservedCostOneYear: number
  diskType: string
  currency: string
  location: string
  versionDate: string
  type: string
  spotInstanceCost: number
  metadata: string
  priceDetail: string
  cloud_id: number
}

interface IDataSourceFlavor {
  name: string
  vCPU: number
  memory: number
  price: number
}

@Component({
  selector: 'table-flavors',
  templateUrl: './table-flavors.component.html',
  styleUrls: ['./table-flavors.component.scss'],
  animations: [simpleFadeAnimation]
})
export class TableFlavorsComponent implements OnInit,AfterViewInit {

  @Input() cloud: ICloud;
  @Input() currencyContract: string;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ["name", "memory", "vCPU", "price"];
  dataSource = new MatTableDataSource<IDataSourceFlavor>([]);

  loadingTable = true;

  totalData: number = 0;
  pageSize: number = 10;
  flavors: IFlavor[];
  loading: boolean= true;

  selectNameFilter = "";
  filterApplied = false;

  sortColumn: string | undefined = undefined;
  direction: string = "";

  constructor(private appService: AppSettingsService) {
   }

  ngOnInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.checkDataLoaded(1);
    
  }


  ngAfterViewInit() {
 
    this.sort.sortChange.subscribe((response) => {
      const {active: column, direction} = response;

      this.direction = direction
      if (direction === "") this.sortColumn = undefined
      else this.sortColumn = column;

      this.flavors = undefined;

      this.checkDataLoaded(1);
    })
  }

  async clear() {
    this.selectNameFilter = "";
    if (this.filterApplied) {
      this.flavors = undefined
      this.checkDataLoaded(1);
      this.filterApplied = false;
    } 
  }

  async filterByName() {
    this.flavors = undefined;
    this.checkDataLoaded(1);

    this.filterApplied = true
  }

  async checkDataLoaded(page: number) {
    if (!this.flavors || !this.flavors[(page-1)*this.pageSize]) {
      await this.updateDataArray(page)
    }
    this.dataSource = new MatTableDataSource(this.flavors.slice((page-1)*this.pageSize, page*this.pageSize));

    this.loadingTable = false;
  }

  async updateDataArray(page: number) {
    this.loading = true;
    const response = await this.getFlavorsCloud(page,this.pageSize);

    const {flavors,paginateInfo} = response.getResponse();

    if (!this.flavors) {
      this.totalData = paginateInfo.total;
      this.flavors = new Array(this.totalData);
    }
    this.flavors.splice((page-1)*this.pageSize,flavors.length,...flavors);

    this.loading = false;
  }

  getFlavorsCloud(page: number,sizePage: number){
    return this.appService.getFlavorsClouds(this.cloud.id,page,sizePage,this.sortColumn,this.direction,this.selectNameFilter).toPromise()
  }

  async pageChanged(event?: PageEvent) {
    await this.checkDataLoaded(event.pageIndex+1)

  }

}
