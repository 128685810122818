import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbThemeService, NbSidebarService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ThemeService } from '../../../@core/services/theme.service';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent implements OnDestroy, OnInit {

  private alive = true;

  sideMenuLogoPath: string;
  showSideMenuLogo: boolean = true;

  currentTheme: string;

  constructor(protected nbThemeService: NbThemeService, private themeService: ThemeService, private sidebarService: NbSidebarService) {
    this.nbThemeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
    });
    this.sidebarService.onToggle().subscribe(toggle => {
      if(toggle.tag === 'menu-sidebar') {
        this.showSideMenuLogo = !toggle.compact;
      }
    });
  }

  ngOnInit() {
    this.sideMenuLogoPath = this.themeService.getSideMenuClientLogoPath();
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
