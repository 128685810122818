import ChartConfigure from "./ChartConfigure"
import { ChartData, PositionType } from "chart.js"
import DatasetConfigure from "./DatasetConfigure"

export default class PieDoughnutConfigure {

    dataset: DatasetConfigure;
    config: ChartConfigure;
    // Line configure Class

    // ? Params

        //! data: List of Array values for is present in the chart
        //! labels: List of x-ticks of the chart
        //! dataNick: List of labels nickname (Optional)
        //! XaxisLabel: Name x-Label of the dataset (Optional)
        //! YaxisLabel: Name y-Label of the dataset (Optional)

    constructor(
        data: number[][],
        labels: string[], 
        type: string,
        objConfig: {
            dataNick?: string[] | null;
            XaxisLabel?: string
            YaxisLabel?: string
            palette?: string
            color?: (string | string[])[]
            legend: boolean,
            legendPosition: PositionType,
            tooltip?: (tooltipItem, data) => string
        } = {} as any 
        ) {
            this.dataset = new DatasetConfigure(data,labels,type,objConfig.dataNick,objConfig.palette,objConfig.color)
            this.config = new ChartConfigure(false,undefined,undefined,objConfig.legend,objConfig.legendPosition,objConfig.tooltip)
    }
}