import { Component, OnDestroy, OnInit } from '@angular/core';
import Container from '../../../@core/entities/Container';
import { Observable, Subject } from 'rxjs';
import { ContainerService } from '../../../@core/services/container.service';
import { takeUntil } from 'rxjs/operators';
import GlobalDataUsavings from '../../../@core/interfaces/GlobalDataUsavings.interface';
import { StatusData } from '../../../@core/enum/EnumStatusData';

@Component({
  selector: 'container-selector',
  templateUrl: './container-selector.component.html',
  styleUrls: ['./container-selector.component.scss']
})
export class ContainerSelectorComponent implements OnInit, OnDestroy {

  containers: GlobalDataUsavings<Container[]> = {data: [], status: StatusData.EMPTY}
  currentContainer: GlobalDataUsavings<Container> = { data: undefined, status: StatusData.EMPTY};

  private unsubscription = new Subject();

  constructor(
    private containerService: ContainerService,
  ) { 
    this.containerService.getContainers().pipe(takeUntil(this.unsubscription)).subscribe(containers => {
      if (containers) {
        this.containers = containers
      }
    })

    this.containerService.getCurrentContainer().pipe(takeUntil(this.unsubscription)).subscribe(container => {
      if (container) {
        this.currentContainer = container
      }
    })
  }

  get hasError() {
    return this.containers.status === StatusData.ERROR || this.currentContainer.status === StatusData.ERROR
  }

  get hasDataCurrentContainer() {
    return this.currentContainer.status === StatusData.READY
  }

  get currentContainerIsEmpty() {
    return this.currentContainer.status === StatusData.EMPTY
  }

  get loadingContainers() {
    return this.containers.status === StatusData.LOADING
  }

  get loadingCurrentContainer() {
    return this.currentContainer.status === StatusData.LOADING
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.unsubscription.next();
  }

  changeContainer(container: Container) {
    const statusContainer = {
      data:container,
      status: StatusData.READY
    }
    this.containerService.setCurrentContainer(statusContainer)
    this.containerService.saveCurrentContainerOnSessionStorage(statusContainer)
  }

}
