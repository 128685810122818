export class RequestResult<T extends { error?: {message: string; name: string; type?: string}} = any> {

    constructor(private success: boolean,
        private response?: T) {
    }

    public isSuccess(): boolean {
        return this.success;
    }

    public isFailed(): boolean {
        return !this.success;
    }

    public getResponse(): T {
        return this.response;
    }

    public getMessage(): string {
        return this.response.error.message;
    }

    public getErrorType(): string {
        return this.response.error.type;
    }

    public getErrorName(): string {
      return this.response.error.name;
  }
}
