import { Injectable, Injector} from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import {  tap } from 'rxjs/operators';
import { NbTokenService, NbAuthService } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { ConfigService } from '../services/config.service';

@Injectable()
export class noAuthInterceptor implements HttpInterceptor {

    constructor(
      private injector: Injector,private router: Router, protected nbTokenService: NbTokenService, protected toastService: NbToastrService, private config: ConfigService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
      if (request.url.indexOf(this.config.getUrlUsavings()) != -1 || request.url.indexOf(this.config.getUrl()) || request.url.indexOf(this.config.getUcloudWeb())) {
        let token;
        this.authService.getToken().subscribe((t:any)=>{
          token = t.token;
        })
        const headers = request.headers.set('token', token);
          const authReq = request.clone({headers});
        return next.handle(authReq).pipe( tap(() => {},  
        (err: any) => {  
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {  
             return;  
            }
            sessionStorage.clear();
            this.nbTokenService.clear();
            this.toastService.info("No authorization, login again.", "Error");
            this.router.navigate(['auth/login']);  
          }  
        }));
      } else {
        return next.handle(request);
      }
    }
    protected get authService(): NbAuthService {
      return this.injector.get(NbAuthService);
    }
}

