import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { catchError, filter, map, take, timeout } from "rxjs/operators";
import { HttpQueueService } from "../../queue/http-queue.service";
import { compareModel } from "../models/compareModel";
import { ContractProps } from "../models/headerModel";
import { RequestResult } from "../models/request-result";
import { ConfigService } from "./config.service";
import { IContract } from "../models/DashboardModels";
import {
  ContainerHintResponseData,
  SuggestionPriceAllVirtualMachinesParams,
  SuggestionPriceAllVirtualMachinesResponse,
} from "../interfaces/dashboardServices.interface";
import { ResponseUsavings } from "../interfaces/ResponseUsavings";
import { IResourceHeatMap } from "../interfaces/billingServices.interface";

@Injectable()
export class DashboardService {
  private suggestedFlavorPricesSubject = new Subject();

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private httpQueue: HttpQueueService
  ) {}

  getCostPerMonthToDashboard(containerUUID: string) {
    return this.http
      .get(
        this.config.getUrlUsavings("/dashboard/usage"),
        this.config.getHttpOptions({ containerUUID })
      )
      .pipe(
        take(1),
        timeout(60000),
        map((x) => new RequestResult(true, x)),
        catchError((err) => this.config.handleError(err))
      );
  }

  getContainer(companyUuid: any) {
    return this.http
      .get(
        this.config.getUrlUsavings("/dashboard/containers"),
        this.config.getHttpOptions({
          tenant: companyUuid,
        })
      )
      .pipe(
        take(1),
        map((x) => new RequestResult(true, x)),
        catchError((err) => this.config.handleError(err))
      )
      .toPromise();
  }

  getContracts() {
    return this.http
      .get<ContractProps[]>(
        this.config.getUrlUsavings("/dashboard/companies"),
        this.config.getHttpOptions()
      )
      .pipe(take(1))
      .toPromise();
  }

  getVmsUUIDByContainer(UUID) {
    return this.http
      .get(
        this.config.getUrl("/usavings/byContainer/" + UUID + "/vms-uuid"),
        this.config.getHttpOptions()
      )
      .pipe(take(1));
  }

  getContainerHintData(containerUuid, contractUuid) {
    return this.http
      .get(
        this.config.getUrlUsavings(
          `/dashboard/${containerUuid}/unused-resources`
        ),
        this.config.getHttpOptions({
          contractUuid: contractUuid,
        })
      )
      .pipe(
        take(1),
        map((x: ContainerHintResponseData) => {
          if (x.hasOwnProperty("error"))
            return new RequestResult<
              ContainerHintResponseData & {
                error?: { message: string; name: string };
              }
            >(false, x);
          return new RequestResult<
            ContainerHintResponseData & {
              error?: { message: string; name: string };
            }
          >(true, x);
        }),
        catchError((err) => {
          return this.config.handleError(err);
        })
      );
  }

  getSuggestionPriceAllVirtualMachines({
    accountProviderIdentifier,
    cloudAccountProvider,
    contractIdentifier,
  }: SuggestionPriceAllVirtualMachinesParams): Observable<RequestResult<ResponseUsavings<SuggestionPriceAllVirtualMachinesResponse>>> {
    return this.http
      .get<any>(
        this.config.getUrlUsavings(
          `/dashboard/${accountProviderIdentifier}/suggest/compare/${cloudAccountProvider}`
        ),
        this.config.getHttpOptions({
          contractIdentifier: contractIdentifier,
        })
      )
      .pipe(
        take(1),
        map(
          (x: ResponseUsavings<SuggestionPriceAllVirtualMachinesResponse>) => {
            return new RequestResult(true, x);
          }
        ),
        catchError((err) => {
          return this.config.handleError<ResponseUsavings<SuggestionPriceAllVirtualMachinesResponse>>(err);
        })
      );
  }

  getSuggestedFlavorPricesSubject(): Subject<any> {
    return this.suggestedFlavorPricesSubject;
  }

  queryp(url: string) {
    if (url.indexOf("?") == -1) return "?";
    else return "&";
  }
}
