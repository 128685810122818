import { Component, Input, OnInit } from '@angular/core';
import { simpleFadeAnimation } from '../../../animations';

@Component({
  selector: 'unique-list',
  templateUrl: './unique-list.component.html',
  styleUrls: ['./unique-list.component.scss'],
  animations: [simpleFadeAnimation]
})
export class UniqueListComponent implements OnInit {

  @Input() title: string;

  @Input() conditionContent: boolean;

  @Input() conditionEmpty: boolean = false;
  @Input() emptyMessage: string;
  @Input() conditionLoading: boolean = false;
  @Input() loadingMessage: string;

  @Input() heightBody: string = 'auto';
  @Input() maxHeightBody: string = '500px';

  constructor() { }

  ngOnInit() {
    if ((this.conditionEmpty && !this.emptyMessage)) {
      throw new Error('Empty condition accepted, but there is no message')
    }

    if ((this.conditionLoading && !this.loadingMessage)) {
      throw new Error('Loading condition accepted, but there is no message')
    }
  }

}
