import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'empty-content-message-usavings',
  templateUrl: './empty-content-message-usavings.component.html',
  styleUrls: ['./empty-content-message-usavings.component.scss']
})
export class EmptyContentMessageUsavingsComponent implements OnInit {

  @Input() description: string

  constructor() { }

  ngOnInit() {
  }

}
