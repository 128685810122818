export const PRODEMGE_THEME = {
    name: 'prodemge',
    base: 'default', //herda as variáveis do default
    variables: {
  
      images: {
        login: '../../../assets/logos/prodemge/login-prodemge.png', // se não houver logo, deixar nulo
        sideMenu: '../../../../assets/logos/prodemge/menu-prodemge.png', // se não houver logo, deixar nulo
        favicon: '../assets/logos/prodemge/favicon.ico' // ./favicon.ico é o default
      },
  
    },
  };
  