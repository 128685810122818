import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MatPaginatorModule,
  MatProgressBarModule,
  MatSortModule,
  MatTableModule
} from "@angular/material";
import { NbAccordionModule, NbDialogModule, NbTooltipModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { InputFileModule } from 'ngx-input-file';
import { noAuthInterceptor } from '../../@core/interceptors/noAuthInterceptor';
import { SharedModule } from '../../@shared/shared.module';
import { ThemeModule } from '../../@theme/theme.module';
import { PipesModule } from '../../pipes/pipes.module';
import { AppTranslationModule } from '../app.translation.module';
import { AppSettingsLayoutComponent } from './app-settings-layout.component';
import { AppSettingsComponent } from './app-settings.component';
import { AppSettingsRoutingModule } from './app-settings.routing';
import { AppSettingsService } from './app-settings.service';
import { CloudsComponentComponent } from './clouds-component/clouds-component.component';
import { CreateCloudDiscountComponent } from './clouds-component/create-cloud-discount/create-cloud-discount.component';
import { CustomCloudDeleteModalComponent } from './clouds-component/custom-cloud-delete-modal/custom-cloud-delete-modal.component';
import { DefaultCostPriceCardComponent } from './clouds-component/default-cost-price-card/default-cost-price-card.component';
import { TableFlavorsComponent } from './clouds-component/table-flavors/table-flavors.component';
import { PreferencesComponentComponent } from './preferences-component/preferences-component.component';


@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    NbDialogModule.forChild(),
    Ng2SmartTableModule,
    InputFileModule,
    AppSettingsRoutingModule,
    NbAccordionModule,
    NgSelectModule,
    AppTranslationModule,
    PipesModule,
    NbTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSortModule,
    SharedModule
  ],
  declarations: [
      AppSettingsLayoutComponent,
      AppSettingsComponent,
      CloudsComponentComponent,
      PreferencesComponentComponent,
      CreateCloudDiscountComponent,
      CustomCloudDeleteModalComponent,
      DefaultCostPriceCardComponent,
      TableFlavorsComponent,
  ],
  entryComponents: [
    CustomCloudDeleteModalComponent
  ],
  providers: [AppSettingsService,
    { provide: HTTP_INTERCEPTORS, useClass: noAuthInterceptor, multi: true},]
})
export class AppSettingsModule { }
