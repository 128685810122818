export const DEFAULT_THEME = {
  name: 'default',
  base: null, //não herda veriáveis
  variables: {

    chartBarsColors: ['#007bff', '#ffc107', '#28a745', '#e83e8c', '#28a745'],

    images: {
      login: './assets/clients/ucloud/usavings.png', // se não houver logo, deixar nulo
      sideMenu: null, // se não houver logo, deixar nulo
      favicon: './logo.png' // ./favicon.png é o default
    },

    echarts: {
      bg: '#ffffff',
      textColor: '#484848',
      axisLineColor: '#bbbbbb',
      splitLineColor: '#ebeef2',
      itemHoverShadowColor: 'rgba(0, 0, 0, 0.5)',
      tooltipBackgroundColor: '#6a7985',
      areaOpacity: '0.7',
    }
  },
};
