import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { noAuthInterceptor } from '../@core/interceptors/noAuthInterceptor';
import { ThemeModule } from '../@theme/theme.module';
import { AppSettingsModule } from './app-settings/app-settings.module';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TableMultipleSearchComponent } from './table-multiple-search/table-multiple-search.component';
import { usavingsHeaderComponent } from './usavings-header/usavings-header.component';
import { XtermLogsComponent } from './xterm-logs/xterm-logs.component';

const COMPONENTS = [
    usavingsHeaderComponent,
    SelectSearchComponent,
    SpinnerComponent,
    XtermLogsComponent,
    TableMultipleSearchComponent
];

@NgModule({
    imports:[
      CommonModule,
      ThemeModule,
      Ng2SmartTableModule,
      AppSettingsModule,
      NgSelectModule
    ],
  declarations: [...COMPONENTS],
  exports:[...COMPONENTS, CommonModule],
  entryComponents:[],
  bootstrap:[XtermLogsComponent]
})
export class UsavingsComponentsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UsavingsComponentsModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: noAuthInterceptor, multi: true},
       ]
    };
  }
}
