import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';

export type MultipleSearchInput = {
  attributes: Attribute[];
  placeholder: string;
}

export type MultipleSearch<T> = {
  attributes: {
    text: string,
    value: keyof T,
  }[];
  placeholder: string;
}

export type Attribute = {
  text: string,
  value: string
}

type Filter = {
  attribute: string,
  value: string,
}

export type MultipleSearchOutput = { [key: string]: string[] }

@Component({
  selector: 'table-multiple-search',
  templateUrl: './table-multiple-search.component.html',
  styleUrls: ['./table-multiple-search.component.scss']
})
export class TableMultipleSearchComponent implements OnInit {

  @Output() search = new EventEmitter<MultipleSearchOutput>();
  @Input() placeholder = "Pesquisar";
  @Input() attributes: Attribute[];

  originalAttr = [];

  inputForm: FormGroup;
  filters: Filter[] = [];

  inputSubject = new Subject<string | null>();

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.buildSearch();
    this.originalAttr = this.attributes;

    this.inputSubject.subscribe((value) => {
      this.attributes = this.attributes.map((e) => {
        if (e.text.includes(':')) {
          const attrText = e.text.split(':')[0];
          const attrValue = e.value.split(':')[0];

          return {
            text: `${attrText}: ${value}`,
            value: `${attrValue}:${value}`
          }
        } else {
          return {
            text: `${e.text}: ${value}`,
            value: `${e.value}:${value}`
          };
        }
      });
    });
  }

  ngOnDestroy() {
    this.inputSubject.unsubscribe();
  }

  onAdd() {
    this.attributes = this.originalAttr;
  }

  onRemove() {
    this.ngSelectComponent.itemsList.setItems(this.originalAttr);
    this.attributes = this.originalAttr;
  }

  buildSearch() {
    this.inputForm = this.fb.group({
      filters: [[], Validators.required]
    });

    this.inputForm.get('filters')
      .valueChanges
      .subscribe(() => {
        const lastSelected = this.ngSelectComponent.itemsList.lastSelectedItem;

        //@ts-ignore
        if (lastSelected && lastSelected.hasOwnProperty('value') && !lastSelected.value.value.includes(':')) {
          this.ngSelectComponent.itemsList.unselect(lastSelected);
        }
      });
  }

  submitSearch() {
    if (this.inputForm.get('filters').value && this.inputForm.get('filters').value.length > 0) {
      const valuesSubmit = this.inputForm.get('filters').value.reduce((acc, curr) => {
        if (curr.includes(':')) {
          const [attr, ...value] = curr.split(':');
          if (acc.hasOwnProperty(attr)) {
            acc[attr].push(value.join(':'));
          } else {
            acc[attr] = [value.join(':')];
          }
        }
        return acc;
      }, {});
      this.search.emit(valuesSubmit);
    } else {
      this.search.emit({});
    }
  }

}
