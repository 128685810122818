import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preferences-component',
  templateUrl: './preferences-component.component.html',
  styleUrls: ['./preferences-component.component.scss']
})
export class PreferencesComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
