import ChartConfigure from "./ChartConfigure"
import { ChartData, ChartDataSets, PositionType } from "chart.js"
import DatasetConfigure from "./DatasetConfigure";

export default class LineConfigure {

    dataset: DatasetConfigure;
    config: ChartConfigure;

    // ? Params

        //! data: List of Array values for is present in the chart
        //! labels: List of x-ticks of the chart
        //! dataNick: List of labels nickname (Optional)
        //! XaxisLabel: Name x-Label of the dataset (Optional)
        //! YaxisLabel: Name y-Label of the dataset (Optional)

    constructor(
        data: number[][],
        labels: string[], 
        type: string,
        objConfig: {
            additionalDatasetConfig: ChartDataSets,
            dataNick?: string[] | null;
            XaxisLabel?: string
            YaxisLabel?: string
            palette?: string
            legend: boolean,
            legendPosition: PositionType,
            color?: (string | string[])[]
            tooltip?: (tooltipItem, data) => string
        } = {} as any
        
        
        ) {
            this.dataset = new DatasetConfigure(data,labels,type,objConfig.dataNick,objConfig.palette,objConfig.color, undefined, objConfig.additionalDatasetConfig)
            this.config = new ChartConfigure(true,objConfig.XaxisLabel,objConfig.YaxisLabel, objConfig.legend,objConfig.legendPosition,objConfig.tooltip)
        
            this.dataset.datasetConfigure.datasets = this.dataset.datasetConfigure.datasets.map(dataset => {
                dataset.fill = false

                return dataset
            })
    }
}