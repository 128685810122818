import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import Chart,{ ChartData, ChartDataSets, ChartOptions, PositionType,  } from "chart.js"
import LineConfigure from './ConfigureCharts/LineConfigure';
import BarConfigure from './ConfigureCharts/BarConfigure';
import PieDoughnutConfigure from './ConfigureCharts/PieDoughnutConfigure';
import LineMultiAxisConfigure from './ConfigureCharts/LineMultiAxisConfigure';


interface IChartConfig {
  dataset: ChartData,
  config: ChartOptions
}

@Component({
  selector: 'charts-usavings',
  templateUrl: './charts-usavings.component.html',
  styleUrls: ['./charts-usavings.component.scss']
})
export class ChartsUsavingsComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;


  @Input() type: string;
  @Input() data: number[][];
  @Input() labelNames: string[];
  @Input() aspectRatio: number = null
  @Input() dataNick: string[] | null = null;
  @Input() XaxisLabel: string = undefined
  @Input() YaxisLabel: string | string[] = undefined 
  @Input() color: (string | string[])[] = undefined
  @Input() tooltip: (tooltipItem, data) => string = undefined
  @Input() legend: boolean = false;
  @Input() legendPosition: PositionType = "right";
  @Input() additionalDatasetConfig: ChartDataSets = {} as ChartDataSets


  chartObject: LineConfigure;

  loading = true
  objectStyleDiv: any = {}

  chart: Chart;

  constructor() {
    
   }



  ngOnInit() {
    
    switch (this.type){
      case "multi-line-axis":
        this.chartObject = new LineMultiAxisConfigure(this.data,this.labelNames,this.type,{
          color:this.color,
          XaxisLabel: this.XaxisLabel,
          YaxisLabel: this.YaxisLabel as string[],
          dataNick: this.dataNick,
          legend: this.legend,
          legendPosition: this.legendPosition,
          tooltip: this.tooltip,
        })
        break;
      case "line":
        this.chartObject = new LineConfigure(this.data,this.labelNames,this.type,{
          color:this.color,
          XaxisLabel: this.XaxisLabel,
          YaxisLabel: this.YaxisLabel as string,
          dataNick: this.dataNick,
          legend: this.legend,
          legendPosition: this.legendPosition,
          tooltip: this.tooltip,
          additionalDatasetConfig: this.additionalDatasetConfig,
        })
        break;
      case "bar":
        this.chartObject = new BarConfigure(this.data,this.labelNames,this.type,{
          color:this.color,
          XaxisLabel: this.XaxisLabel,
          YaxisLabel: this.YaxisLabel as string,
          legend: this.legend,
          legendPosition: this.legendPosition,
          dataNick: this.dataNick,

        })
        break;
      case "doughnut":
      case "pie":
        this.chartObject = new PieDoughnutConfigure(this.data,this.labelNames,this.type,
          {
            color: this.color,
            XaxisLabel: this.XaxisLabel,
            YaxisLabel: this.YaxisLabel as string,
            legend: this.legend,
            legendPosition: this.legendPosition,
            dataNick: this.dataNick,
            tooltip: this.tooltip
          })
        break;
      default:
        break;
    }

    if (this.aspectRatio) this.chartObject.config.setAspectRatio(this.aspectRatio);
    const type = this.type === "multi-line-axis" ? "line" : this.type;

    this.chart = new Chart(this.canvas.nativeElement.getContext('2d'),{
      data: this.chartObject.dataset.datasetConfigure,
      options: this.chartObject.config.optionsConfigure,
      type: type,
    })

  }

}
