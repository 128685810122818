import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'opensearch-icon',
  templateUrl: './opensearch-icon.component.html',
  styleUrls: ['./opensearch-icon.component.scss']
})
export class OpensearchIconComponent implements OnInit {

  @Input() size = 32;
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

}
