import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export type DefaultListUsavingsParams = {
  attributeName: string;
  headerName: string;
  isArray?: boolean;
  weight?: number;
}

export type ListUsavingsParams<T> = { attributeName: keyof T; } & DefaultListUsavingsParams;

@Component({
  selector: 'default-list-usavings',
  templateUrl: './default-list-usavings.component.html',
  styleUrls: ['./default-list-usavings.component.scss']
})
export class DefaultListUsavingsComponent<T> implements OnInit {

  @Input() data: any[];
  @Input() attributesDataConfig: DefaultListUsavingsParams[];
  @Input() showNumList: boolean = true;

  @Input() disableFunctionActions: (data: T) => boolean = () => false;
  @Input() actions: {
    iconClass: string
    alias: string
  }[] = undefined

  @Output() actionSelected = new EventEmitter<{alias: string; row: any}>();


  constructor() { }

  ngOnInit() {
    for (let { attributeName } of this.attributesDataConfig) {
      if (!this.data[0].hasOwnProperty(attributeName)) throw new Error("The configured attribute is not present in the data source");
    }
  }

  identify(index, item) {
    return item.iconClass
  }

  onSelectAction(aliasAction: string, row){
    this.actionSelected.emit({alias: aliasAction, row});
  }


}
