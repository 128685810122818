import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "sizePipe" })
export class SizePipe implements PipeTransform {
  transform(bytes: any, precision?: any): string {
    let number = 0;
    let units = [/*'bytes', 'KB',*/ "MB", "GB", "TB", "PB"];

    if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes <= 0) {
      return "0" + " " + units[number];
    }

    if (typeof precision === "undefined") {
      precision = 1;
    }

    let byte = bytes;
    let unitValue: string;
    number = Math.floor(Math.log(bytes) / Math.log(1024));
    if (number < 0) {
      byte = bytes.toFixed(5);
      unitValue = units[0];
    } else {
      unitValue = units[number];
    }

    switch (unitValue) {
      case "GB":
        byte /= 1024;
        break;
      case "TB":
        byte /= 1024 * 1024;
        break;
      case "byteT":
        byte /= 1024 * 1024 * 1024;
        break;
    }
    byte = Number(byte);
    return `${byte.toFixed(precision)} ${unitValue}`;
  }

  byteToGigaByte(bytes: number): number {
    return (bytes / Math.pow(10, 9));
  }

  byteToMegaByte(bytes: number): number {
    return (bytes / Math.pow(10, 6));
  }
}
