import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cloud-trail',
  templateUrl: './cloud-trail.component.html',
  styleUrls: ['./cloud-trail.component.scss']
})
export class CloudTrailComponent implements OnInit {

  @Input() size = 32;
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

}
