import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsUsavingsComponent } from './charts-usavings.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [ChartsUsavingsComponent],
  imports: [
    CommonModule,
    ChartsModule
  ],
  exports: [
    ChartsUsavingsComponent,CommonModule
  ]
})
export class ChartsUsavingsModule { }
