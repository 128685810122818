import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'default-cost-price-card',
  templateUrl: './default-cost-price-card.component.html',
  styleUrls: ['./default-cost-price-card.component.scss']
})
export class DefaultCostPriceCardComponent implements OnInit {

  @Input() cpuPrice;
  @Input() memoryPrice;
  @Input() currency;

  constructor() { }

  ngOnInit() {
  }

}
