import { Component, EventEmitter, Input, Output } from '@angular/core';

export type IconValue = 'fas fa-info' | 'fas fa-arrow-left' | 'fas fa-shield-alt' | 'fas fa-leaf' | 'fas fa-id-card' | 'fas fa-user-shield' | 'fas fa-code' | 'fas fa-memory' | 'fas fa-wallet' | 'fas fa-chevron-right' | 'fas fa-table' | 'fas fa-envelope' | 'fas fa-envelope-open-text' | 'fas fa-calendar-alt' | 'fas fa-money-bill' | 'fas fa-check-circle' | 'fas fa-exclamation-circle' | 'fas fa-cube' | 'fas fa-exchange-alt' | 'fas fa-filter' | 'fas fa-save' | 'fas fa-server' | 'fas fa-clock' | 'far fa-calendar-alt' | 'fas fa-layer-group' | 'fas fa-signal' | 'fas fa-clone' | 'fas fa-network-wired' | 'fas fa-sitemap' | 'fas fa-ethernet' | 'fas fa-list' | 'fas fa-check-double'; // Add new icon in the IconValue type

@Component({
  selector: 'card-default-icon',
  templateUrl: './card-default-icon.component.html',
  styleUrls: ['./card-default-icon.component.scss']
})
export class CardDefaultIconComponent {

  @Input() iconClass: IconValue;
  @Output() onClick = new EventEmitter<void>();

  constructor() { /* Empty */ }

  get existsObservers(): boolean {
    return this.onClick.observers.length > 0;
  }

  get icon(): string {
    return this.iconClass.toString();
  }

  iconClick() {
    this.onClick.emit();
  }
}
