import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import * as configJson from '../../../assets/config/config.json';
import { RequestResult } from '../models/request-result';

export type Environment = {
  production?: boolean;
  client: string;
  usavingsApi: string;
  ucloudApi: string;
  ucloudWeb: string;
  environment: string;
  blockedRoutesDemo: string[];
  systemName: string;
  clientName: string;
  theme: string;
  localServerDomain: string;
  localServerPort: number;
  defaultCurrency: string;
  currency_available: string[];
  cloudsComparer: string[];
};

interface headersProps {
  headers: {
    Authorization: string
    token: string
    region: string
    [key: string]: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  static changeLang = new EventEmitter();
  private envConfig: Environment
  private envConfigFilePath = 'assets/config/config.json';

  constructor(private http: HttpClient, private handler: HttpBackend, private injector: Injector) {
    this.http = new HttpClient(handler);
    this.envConfig = configJson;
    this.getJSON();
  }

  public async getJSON(): Promise<void> {
    return this.http.get<Environment>(this.envConfigFilePath).toPromise()
      .then((response: Environment) => {
        this.envConfig = response;
      }).catch((error: any) => {
        console.error('Error reading environment configuration file', JSON.stringify(error));
      });
  }

  getConfig(): Environment {
    return this.envConfig;
  }

  sendLang(lang: any) {
    ConfigService.changeLang.emit(lang);
  }

  getUrl(path?: string): string {
    return this.envConfig.ucloudApi + (path || '');
  }

  getUrlUsavings(path?: string): string {
    return this.envConfig.usavingsApi + (path || '');
  }

  getUcloudWeb(path?: string): string {
    return this.envConfig.ucloudWeb + (path || '');
  }

  getTheme(): string {
    return this.envConfig.theme;
  }

  getEnvironment() {
    return this.envConfig.environment
  }

  getBlockedRoutesDemo() {
    return this.envConfig.blockedRoutesDemo
  }

  getSystemName(): string {
    return this.envConfig.systemName;
  }

  getCloudsComparer(): string[] {
    return this.envConfig.cloudsComparer;
  }

  getClientName(): string {
    return this.envConfig.clientName;
  }

  localUrl(path?: string): string {
    return this.getLocalDomain() + (path || '');
  }

  public getDefaultCurrency(): string {
    if (this.envConfig.currency_available.includes(this.envConfig.defaultCurrency))
      return this.envConfig.defaultCurrency;
    else {
      return 'USD';
    }
  }

  public getAvailablesCurrency(): any {
    return this.envConfig.currency_available;
  }

  public async exchangeCurrency(currency, price) {
    var rateC = 1;
    var defaultCurrency = this.getDefaultCurrency();
    var cached = localStorage.getItem(`baseCurr:${currency}`);
    if (cached != null) {
      var getRates = JSON.parse(cached);
      return price * getRates.rates[defaultCurrency];
    } else {
      await this.http.get(`https://api.exchangeratesapi.io/latest?base=${currency}`).subscribe((res: any) => {
        localStorage.setItem(`baseCurr:${currency}`, JSON.stringify(res));
        rateC = res.rates[defaultCurrency];
      });
      return price * rateC;
    }
  }

  public async setCachedStorage() {
    var cachedUSD = localStorage.getItem(`baseCurr:USD`);
    var cachedBRL = localStorage.getItem(`baseCurr:BRL`);

    if (cachedUSD == null) {

      await this.http.get(`https://api.exchangeratesapi.io/latest?base=USD`).pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.handleError(err)))
        .subscribe(async (res: RequestResult) => {
          if (res.isSuccess()) {
            localStorage.setItem(`baseCurr:USD`, JSON.stringify(res.getResponse()));
          } else {
            await this.http.get('assets/currency/baseCurrUSD.json').subscribe(data => localStorage.setItem(`baseCurr:USD`, JSON.stringify(data)))
          }
        });
    }
    if (cachedBRL == null) {
      await this.http.get(`https://api.exchangeratesapi.io/latest?base=BRL`).pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.handleError(err)))
        .subscribe(async (res: RequestResult) => {
          if (res.isSuccess()) {
            localStorage.setItem(`baseCurr:BRL`, JSON.stringify(res.getResponse()));
          } else {
            await this.http.get('assets/currency/baseCurrBRL.json').subscribe(data => localStorage.setItem(`baseCurr:BRL`, JSON.stringify(data)))
          }
        });
    }
  }

  private getLocalDomain(): string {
    return document.location.protocol + "//" + this.envConfig.localServerDomain + ":" + this.envConfig.localServerPort;
  }

  public getUserCompanies(userUuid: string) {
    return new Promise(async (resolve) => {
      await this.http.get(`${this.getUrl()}/users/${userUuid}/companies`, this.getHttpOptions()).pipe(take(1), map(res => new RequestResult(true, res)), catchError(err => this.handleError(err)))
        .subscribe((res: RequestResult) => {
          if (res.isSuccess()) resolve(res.getResponse());
        });
    })
  }

  public extractData(response: HttpResponse<any>) {
    const data = response.body;
    return data;
  }

  public handleError<T = any>(response: any): Observable<RequestResult<T>> {
    return observableOf(new RequestResult(false, response));
  }

  public handleErrorResquestResult(response: any): RequestResult {
    return new RequestResult(false, response);
  }

  public getHttpOptions(headers?: any): headersProps {
    if (!headers) headers = {};
    this.authService.getToken().subscribe(x => {
      headers["Authorization"] = x['token'];
      headers["token"] = x['token'];
    });
    return { headers: headers };
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

  public treatResquestData(req: Observable<any>) {
    return new Promise((resolve, reject) => {
      req.subscribe((res: RequestResult) => {
        if (res.isFailed()) reject(res.getMessage());
        else resolve(res.getResponse());
      })
    })
  }

}

export function loadConfiguration(config: ConfigService) {
  return () => config.getConfig();
}

export function loadExchanged(config: ConfigService) {
  return () => config.setCachedStorage();
}
