import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vpc-icon',
  templateUrl: './vpc-icon.component.html',
  styleUrls: ['./vpc-icon.component.scss']
})
export class VpcIconComponent implements OnInit {

  @Input() size = 32;
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

}
