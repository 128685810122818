import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-default-info',
  templateUrl: './card-default-info.component.html',
  styleUrls: ['./card-default-info.component.scss']
})
export class CardDefaultInfoComponent implements OnInit {

  @Input() tooltipMessage: string

  constructor() { }

  ngOnInit() {
  }

}
