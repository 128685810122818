import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageUsavingsComponent } from './components/error-message-usavings/error-message-usavings.component';
import { EmptyContentMessageUsavingsComponent } from './components/empty-content-message-usavings/empty-content-message-usavings.component';
import { AppTranslationModule } from '../../components/app.translation.module';

const COMPONENTS = [ErrorMessageUsavingsComponent, EmptyContentMessageUsavingsComponent]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    AppTranslationModule
  ],
  exports: [...COMPONENTS]
})
export class UtilsModule { }
