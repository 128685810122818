import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'error-message-usavings',
  templateUrl: './error-message-usavings.component.html',
  styleUrls: ['./error-message-usavings.component.scss']
})
export class ErrorMessageUsavingsComponent implements OnInit {

  @Input() description: string

  constructor() { }

  ngOnInit() {
  }

}
