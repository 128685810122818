import ChartConfigure from "./ChartConfigure"
import { ChartData, PositionType } from "chart.js"
import DatasetConfigure from "./DatasetConfigure";

export default class LineMultiAxisConfigure {

    dataset: DatasetConfigure;
    config: ChartConfigure;

    // ? Params

        //! data: List of Array values for is present in the chart
        //! labels: List of x-ticks of the chart
        //! dataNick: List of labels nickname (Optional)
        //! XaxisLabel: Name x-Label of the dataset (Optional)
        //! YaxisLabel: Name y-Label of the dataset (Optional)

    constructor(
        data: number[][],
        labels: string[], 
        type: string,
        objConfig: {
            dataNick?: string[] | null;
            XaxisLabel?: string
            YaxisLabel?:  string[]
            palette?: string
            color?: (string | string[])[]
            legend: boolean,
            legendPosition: PositionType,
            tooltip?: (tooltipItem, data) => string
        } = {} as any
        
        
        ) {
            if (data.length !== 2 || objConfig.YaxisLabel.length !== 2) {
                throw new Error("Data size invalid. Must be 2 datasets.")
            }

            this.dataset = new DatasetConfigure(data,labels,"line",objConfig.dataNick,objConfig.palette,objConfig.color);
            this.dataset.setColorDataset(1,this.dataset.getLightColor().slice(0,-2)+"0.5)");
            this.dataset.datasetConfigure.datasets[0].yAxisID = "y1";
            this.dataset.datasetConfigure.datasets[1].yAxisID = "y2"


            this.config = new ChartConfigure(true,objConfig.XaxisLabel,undefined, objConfig.legend,objConfig.legendPosition,objConfig.tooltip)
            this.config.setMultiAxis();
            this.config.setAxisNameLabelY(objConfig.YaxisLabel[0],0)
            this.config.setAxisNameLabelY(objConfig.YaxisLabel[1],1)
            this.config.getLegend()
        
            this.dataset.datasetConfigure.datasets = this.dataset.datasetConfigure.datasets.map(dataset => {
                dataset.fill = false

                return dataset
            })
    }
}