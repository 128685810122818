import { Component, Input } from '@angular/core';

@Component({
  selector: 'dynamodb',
  templateUrl: './dynamodb.component.html',
  styleUrls: ['./dynamodb.component.scss']
})
export class DynamodbComponent {

  @Input() size: number = 32;
  @Input() color: string;

  constructor() { }
}
