import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CloudsModalDiscount } from '../../../../@core/models/cloudsModaldiscount';
import { listCloudModal } from '../../../../@core/models/listCloudModel';
import { RequestResult } from '../../../../@core/models/request-result';
import { SizePipe } from '../../../../pipes/size.pipe';
import { AppSettingsService } from '../../app-settings.service';
import { AppTranslationModule } from '../../../app.translation.module';

@Component({
  selector: 'create-cloud-discount',
  templateUrl: './create-cloud-discount.component.html',
  styleUrls: ['./create-cloud-discount.component.scss'],
  providers: [SizePipe],
})
export class CreateCloudDiscountComponent implements OnInit {

  constructor(protected sizePipe: SizePipe, private appService: AppSettingsService,
    private toastService: NbToastrService,
    private i18n: I18n,
    private translate: AppTranslationModule) {
  }
  @Input() clouds : any;
  @Input() contractUuid: string;
  @Input() userId: number;
  @Input() userHasPermission: boolean = false;
  @Output() updateClouds = new EventEmitter<string>();
  cloudDiscount: any;
  cloudNameDiscount: any;
  discount: any;

  calculatingPrices: boolean = false;
  showErrorAlert: boolean = false;
  loadedMessage: string;
  cloudName: any = [];
  userData: any = [];



  async ngOnInit() {
    await this.setVariables();
    // this.mainFunc();
  }

  checkNameCloudExists(nameCloud){
    return this.clouds.map(cloud => cloud.name.split("@")[0]).includes(nameCloud.split("@")[0])
  }



  async createCloudWithDiscount() {
    this.calculatingPrices = true;

    let newNameCloud = this.cloudNameDiscount;

   let newTypeCloud = this.cloudDiscount.contract ? `${this.cloudDiscount.name}@${this.cloudDiscount.contract}` : this.cloudDiscount.name;
   

    let data: CloudsModalDiscount = {
      name: newNameCloud,
      type: newTypeCloud,
      discount: this.discount,
      contractUuid: this.contractUuid,
      userId: this.userId,
      typeImport: 'discount'
    }

    if (this.checkNameCloudExists(data.name)){
      this.toastService.danger(await this.translate.getTranslation('Cloud name already exist! Try another.'), this.i18n('Ops!'));
      this.calculatingPrices = false;
    } else {
      this.appService.importCloud(data).subscribe(async (res: RequestResult) => {
        this.closeErrorAlert();
        if (res.isSuccess()) {
          this.toastService.success(await this.translate.getTranslation('Cloud imported!'), await this.translate.getTranslation('Good!'));
          this.discount = '';
          this.cloudNameDiscount = '';
          this.calculatingPrices = false;
          this.cloudDiscount = '';
          this.mainFunc();
        } else {
          this.loadedMessage = null;
          this.captureError(res.getResponse());
          this.toastService.danger(await this.translate.getTranslation('Failed on importing cloud.'), this.i18n('Ops!'));
          this.calculatingPrices = false;
          this.cloudDiscount = '';
        }
      });
    }
  }

  closeErrorAlert() {
    this.showErrorAlert = false;
  }

  async mainFunc() {
    await this.setVariables();
    await this.listClouds();
  }

  setVariables() {
    return new Promise<void>(resolve => {
      this.loadedMessage = null;
      resolve();
    })
  }

  // listCloudErr = { error: false, message: "" };

  listClouds(params: listCloudModal = { showAll: false, showFlavors: true }) {
    this.updateClouds.emit()
  }

  importErrors = [];
  deleteErrors = [];
  async captureError(res, type = `import`) {
    if (type == 'import') {
      this.importErrors = [];
      if (res.hasOwnProperty('error')) {
        if (res.error.hasOwnProperty('message')) {
          this.importErrors.push(res.error.sqlMessage);
        }
      } else {
        this.importErrors.push(await this.translate.getTranslation('An unexpected error has occurred.'));
      }
    } else if (type == 'delete') {
      this.deleteErrors = [];
      if (res.hasOwnProperty('error')) {
        res.error.forEach(err => {
          if (err.hasOwnProperty('message')) {
            this.deleteErrors.push(err.message);
          }
        })
      } else {
        this.deleteErrors.push(await this.translate.getTranslation('An unexpected error has occurred.'));
      }
    }
  }
}
