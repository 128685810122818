import IContract from "../interfaces/contract.interface";

export default class Contract {
    id: number
    currency: string
    decimalPlace: number
    symbolCurrency: string
    name: string
    ucloudIdentifier: string
    defaultCost: {
        cpuPrice?: number | undefined;
        memoryPrice?: number | undefined;
    } | undefined
    

    constructor(params: IContract) {
        this.fromJson(params);
      }
    
    fromJson(params: IContract) {
        this.id = params.id;
        this.currency = params.currency;
        this.decimalPlace = params.decimalPlace;
        this.symbolCurrency = params.symbolCurrency;
        this.name = params.name;
        this.ucloudIdentifier = params.ucloudIdentifier;
        this.defaultCost = params.defaultCost;
    }

    toJson(): IContract {
        return {
            id: this.id,
            currency: this.currency,
            decimalPlace: this.decimalPlace,
            symbolCurrency: this.symbolCurrency,
            name: this.name,
            ucloudIdentifier: this.ucloudIdentifier,
            defaultCost: this.defaultCost
        }
    }


}