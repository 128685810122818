import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'reload-button',
  templateUrl: './reload-button.component.html',
  styleUrls: ['./reload-button.component.scss']
})
export class ReloadButtonComponent implements OnInit {

  @Input() color = "var(--dyn-main-color)"
  @Input() borderColor = "var(--dyn-main-color)"
  @Input() backgroundColor = "white"
  @Input() spacing = "6px"
  @Input() size = "18px"
  @Input() radius = "8px"

  @Output() onClick = new EventEmitter<void>();

  hover;

  styleDiv
  styleDivHover
  styleIcon
  styleIconHover

  constructor() { }

  setHover(value: boolean){
    this.hover = value;
  }

  ngOnInit() {
    this.styleDiv = {
      'padding': 0,
      'background-color': this.backgroundColor,
      'border-color': this.borderColor,
      'color': this.color,
      'cursor': 'pointer',
      "border-radius": this.radius
    };
    this.styleDivHover = {
      'padding': 0,
      'background-color': this.color,
      'border-color': this.borderColor,
      'color': this.backgroundColor,
      'cursor': 'pointer',
      'border-radius': this.radius
    };
    this.styleIcon = {
      'margin': this.spacing,
      'font-size': this.size,
    }
    this.styleIconHover = {
      'margin': this.spacing,
      'font-size': this.size,
    }
  }

  clickButton() {
		this.onClick.emit();
	}

}
