import IContainer from "../interfaces/container.interface";

export default class Container {
    name: string
    adminIpAddress: string
    ucloudIdentifier: string
    public: boolean
    type: string
    symbolCurrency: string

    constructor(params: IContainer) {
        this.fromJson(params);
      }
    
    fromJson(params: IContainer) {
        this.name = params.name;
        this.adminIpAddress = params.adminIpAddress;
        this.public = params.public;
        this.type = params.type;
        this.ucloudIdentifier = params.ucloudIdentifier;
        this.symbolCurrency= params.symbolCurrency;
    }

    toJson(): IContainer {
        return {
            name: this.name,
            adminIpAddress: this.adminIpAddress,
            public: this.public,
            symbolCurrency: this.symbolCurrency,
            ucloudIdentifier: this.ucloudIdentifier,
            type: this.type
        }
    }


}