import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";

import { Clouds, Logs } from "../../interfaces/jobs";
import { ITerminalOptions, Terminal } from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { FitAddon } from 'xterm-addon-fit';
import { SearchAddon } from 'xterm-addon-search';
import { AppTranslationModule } from "../app.translation.module";

export type XTerminalControl = {
  rows: number
  cols: number
  toggleContainer: Clouds
}

@Component({
  selector: "xterm-logs",
  templateUrl: "./xterm-logs.component.html",
  styleUrls: ["./xterm-logs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XtermLogsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  _rows: number = 15
  _cols: number = 70
  terminal: Terminal;
  webLinksAddon = new WebLinksAddon();
  fitAddon = new FitAddon();
  searchAddon = new SearchAddon();

  @Input() _termControl: XTerminalControl = { rows: this._rows, cols: this._cols, toggleContainer: Clouds.AWS };
  @Input() _logs: Logs[];

  @ViewChild('terminal') termianlWrapper: ElementRef;

  private readonly resizeFit = () => this.fitAddon.fit();
  private readonly prompt = () => this.terminal.write('\r\n');
  private readonly write = (log: Logs) => {
    if (log.isError) this.writeColor.error(log.message);
    else this.writeColor.info(log.message);

    this.prompt();
  }

  constructor(
    private translate: AppTranslationModule
  ) {
    this.terminal = new Terminal(this.baseTerminalOptions);
    this.terminal.loadAddon(this.fitAddon);
    this.terminal.loadAddon(this.searchAddon);
    this.terminal.loadAddon(this.webLinksAddon);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes._logs && !changes._logs.firstChange) this.updateLogs();
  }

  async ngAfterViewInit(){
    this.terminal.open(this.termianlWrapper.nativeElement);
    this.prompt();
    // this.writeColor.info(await this.translate.getTranslation('Welcome to uSavings terminal!'));
    // this.prompt();
    if (this._logs) this.updateLogs();
  }

  ngOnInit(): void {
    this.resizeFit();
  }

  ngOnDestroy() {
    if (this.terminal) this.terminal.dispose();
  }

  onFilterLogs() {
    if (this._logs && this._termControl.toggleContainer) {
      this.onClearLogs();

      this._logs.forEach((log) => {
        if (log.cloud === this._termControl.toggleContainer) this.write(log);
      });
    }
  }

  onResize() {
    this.resizeFit();
  }

  async updateLogs(): Promise<void> {
    this.terminal.clear()
    this.writeColor.info(await this.translate.getTranslation('Welcome to uSavings terminal!'));
    this.prompt();
    if (this._logs) this._logs.forEach((log) => this.write(log));
  }

  onClearLogs(): void {
    this.terminal.clear();
  }

  baseTerminalOptions: ITerminalOptions = {
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: 0,
    fontWeight: '400',
    fontFamily: 'Consolas, "Courier New", monospace',
    bellStyle: 'sound',
    cursorBlink: false,
    theme: { background: '#263238' },
    rendererType: 'canvas',
    scrollback: Number.MAX_SAFE_INTEGER,
  };

  baseTheme = {
    foreground: '#F8F8F8',
    background: '#2D2E2C',
    selectionBackground: '#5DA5D533',
    black: '#1E1E1D',
    brightBlack: '#262625',
    red: '#CE5C5C',
    brightRed: '#FF7272',
    green: '#5BCC5B',
    brightGreen: '#72FF72',
    yellow: '#CCCC5B',
    brightYellow: '#FFFF72',
    blue: '#5D5DD3',
    brightBlue: '#7279FF',
    magenta: '#BC5ED1',
    brightMagenta: '#E572FF',
    cyan: '#5DA5D5',
    brightCyan: '#72F0FF',
    white: '#F8F8F8',
    brightWhite: '#FFFFFF',
    border: '#85858a'
  };

  writeColor = {
    success: (message: string) => this.terminal.write(`\x1b[32m$ ${message}\x1b[0m`),
    error: (message: string) =>  this.terminal.write(`\x1b[31m$ ${message}\x1b[0m`),
    info: (message: string) => this.terminal.write(`\x1b[36m$ ${message}\x1b[0m`),
    warning: (message: string) => this.terminal.write(`\x1b[33m$ ${message}\x1b[0m`),
  };
}
