import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'absolute', pure: false })
export class AbsolutePipe implements PipeTransform {
  transform(value: number, args?: any): number  {

    return Math.abs(value);

  }
}
