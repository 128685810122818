import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, take } from "rxjs/operators";
import { RequestResult } from "../models/request-result";
import { ConfigService } from "./config.service";
import { BehaviorSubject } from "rxjs";
import { IUser } from "../interfaces/user.interface";
import { ResponseUsavings } from "../interfaces/ResponseUsavings";
import { NbToastrService } from "@nebular/theme";
import { Router } from "@angular/router";
import { AppTranslationModule } from "../../components/app.translation.module";
import { NbTokenService } from "@nebular/auth";
import User from "../entities/User";

type UserSession = {
  login: string;
};

@Injectable({
  providedIn: "root",
})
export class UserService {

  private user: BehaviorSubject<User> = new BehaviorSubject(undefined);

  private hasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient, 
    private config: ConfigService,
    protected toastService: NbToastrService,
    protected tokenService : NbTokenService,
    protected router: Router,
    private translate: AppTranslationModule,) {}

  
  getUser() {
    return this.user.asObservable();
  }
  getHasError() {
    return this.hasError.asObservable();
  }

  getUserByTokenProm() {
    return this.http
      .get(this.config.getUrlUsavings("/users/byToken/"), this.config.getHttpOptions())
      .pipe(
        take(1),
        map((x) => {
          return new RequestResult(true, x);
        }),
        catchError((err) => {
          return this.config.handleError(err);
        })
      )
      .toPromise();
  }

  loadUser() {

    this.hasError.next(false)

    // TODO mudar pra cookies
    const cachedUser = sessionStorage.getItem("userData");

    if (!cachedUser) {
      const requestGetUserByToken = this.getUserByToken();
      const subscription = requestGetUserByToken.subscribe(responseUserToken => {
        if (responseUserToken.isSuccess()) {
          const response = responseUserToken.getResponse();

          const user = {
            defaultCompany: response.defaultCompany,
            isAdmin: response.companyAdmin,
            userId: response.userId,
            userUuid: response.ucloudIdentifier
          }

          sessionStorage.setItem("userData",JSON.stringify(user));
          this.user.next(new User(user));
        } else {
          this.user.next(undefined)
          this.hasError.next(true)


          this.tokenService.clear();
          this.toastService.info(this.translate.instant('Your session has expired.'), this.translate.instant('Error!'));
          this.router.navigateByUrl('/auth/login');
        }

        subscription.unsubscribe()
      })
    } else {
      this.user.next(JSON.parse(cachedUser))
      
    }
  }


  getUserByToken() {

    return this.http
    .get(this.config.getUrlUsavings("/users/byToken/"), this.config.getHttpOptions())
    .pipe(
      take(1),
      map((x: IUser) => {
        return new RequestResult<ResponseUsavings<IUser>>(true, x);
      }),
      catchError((err) => {
        return this.config.handleError(err);
      })
    );
  }

  Logout() {
    return this.http
      .post(
        this.config.getUrlUsavings("/users/logout"),
        {},
        this.config.getHttpOptions()
      )
      .pipe(
        take(1),
        map((x) => {
          return new RequestResult(true, x);
        }),
        catchError((x) => {
          return this.config.handleError(x);
        })
      );
  }

  async getUserData(name: any) {
    return this.http
      .get(
        this.config.getUrlUsavings(`/users/search/${name}`),
        this.config.getHttpOptions()
      )
      .pipe(
        take(1),
        map((res) => {
          return new RequestResult(true, res);
        }),
        catchError((res) => {
          return this.config.handleError(res);
        })
      )
      .toPromise();
  }

  async getUsernameByToken() {
    return await this.http
      .get<UserSession[]>(
        this.config.getUrlUsavings(`/users/session`),
        this.config.getHttpOptions()
      )
      .toPromise();
  }
}
