import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { SizePipe } from '../../pipes/size.pipe';
import { NbDialogRef } from '@nebular/theme';
import { CloudsModal } from '../../@core/models/cloudsModal';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppTranslationModule } from '../app.translation.module';

@Component({
  selector: 'app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss'],
  providers:[SizePipe]
})
export class AppSettingsComponent implements OnInit {

  constructor(private i18n: I18n,
    private translate: AppTranslationModule) {}
    
    async ngOnInit(){
      this.settingHeader =  await this.translate.getTranslation("Welcome to you first time on uSavings.");
    }

  canLoad = false;
  settingHeader:string 

  selectedNavigation = "clouds";

  changeTable(event){
    switch (event) {
      case 'clouds':
        this.selectedNavigation = 'clouds';
        break;
        case 'preferences':
          this.selectedNavigation = 'preferences';
          break;
      default:
          this.selectedNavigation = 'clouds';
        break;
    }
  }

}
