import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter', pure: false })
export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any): any[] {
    if (!value) return null;
    if (!args) return value;

    if (typeof args === 'string') {
      args = args.toLowerCase();
      return value.filter((item) => JSON.stringify(item).toLowerCase().includes(args));
    }

    const keys = Object.keys(args);
    return value.filter((item) => {
      return keys.every((key) => {
        return item[key].toLowerCase().includes(args[key].map((arg) => arg.toLowerCase()));
      });
    });
  }
}
