import { CurrencyPipe, registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { Pipe, PipeTransform } from '@angular/core';
import { ContractService } from '../@core/services/contract.service';
registerLocaleData(ptBr)

@Pipe({ name: 'currencyFormat' })
export class CurrencyFormatPipe implements PipeTransform {
  private pipeLocales: { [key: string]: string } = { 'BRL': 'pt-BR', 'USD': 'en-US', 'CAD': 'en-CA', 'MXL': 'es-MX', 'EUR': 'eu' }
  private pipeSymbols: { [key: string]: string } = { 'BRL': 'R$', 'USD': '$', 'CAD': '$', 'MXL': '$', 'EUR': '€' }
  private currencyContract

  constructor(private contractService: ContractService) {
    this.currencyContract = this.contractService.getCurrencyContract()
  }

  get locales() {
    return this.pipeLocales;
  }

  get symbol() {
    return this.pipeSymbols;
  }

  transform(value: number, locale: string = "USD", number_format: string = '1.2-4'): string {
    if (value !== undefined) {

      if (this.currencyContract) {
        locale = this.currencyContract;
      }

      let currencyPipe = new CurrencyPipe(this.locales[locale]);

      let new_value = currencyPipe.transform(value, locale, this.symbol[locale], number_format);
      return new_value
    }
    return null;
  }

  unformed(value: string): number {
    if (value) {
      let new_value = value.replace(/[^0-9\.-]+/g, "");
      return parseFloat(new_value);
    }
  }
}
