import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@theme/theme.module';
import { MessageErrorComponentComponent } from './message-error-component/message-error-component.component';

const COMPONENTS = [
    MessageErrorComponentComponent
]

@NgModule({
  declarations: [...COMPONENTS, ],
  imports: [
    CommonModule,
    ThemeModule
  ],
  exports:[...COMPONENTS],
})
export class ErrorModule { }
