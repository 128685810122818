export const TELMEX_THEME = {
  name: 'telmex',
  base: 'default', //herda as variáveis do default
  variables: {

    images: {
      login: '../../../assets/logos/login-telmex.png', // se não houver logo, deixar nulo
      sideMenu: '../../../../es/assets/logos/menu-telmex.png', // se não houver logo, deixar nulo
      favicon: '../es/assets/logos/favicon-telmex.ico' // ./favicon.ico é o default
    },

  },
};
