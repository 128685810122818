import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'message-error-component',
  templateUrl: './message-error-component.component.html',
  styleUrls: ['./message-error-component.component.scss']
})
export class MessageErrorComponentComponent implements OnInit {

  @Input() messageError = 'An error has occurred';

  constructor() { }

  ngOnInit() {
  }

}
