import { Component, OnInit, Input } from '@angular/core';
import { AppTranslationModule } from '../app.translation.module';

@Component({
  selector: 'usavings-header',
  template: `
  <nb-card class="page-header">
    <nb-card-header>
      <h1>
        <i class="{{icon}}"></i>
        {{headerTranslated}} <br>
        <blockquote>
          <small class="ng-binding">{{descriptionTranslated}}</small>
        </blockquote>
      </h1>
    </nb-card-header>
</nb-card>
  `,
  styleUrls: ['./usavings-header.component.scss']
})
export class usavingsHeaderComponent implements OnInit {

  constructor(private translate: AppTranslationModule){

  }
  
  @Input() icon : string = "fas fa-desktop";
  @Input() header : string = "Usavings";
  @Input() description : string = "";

  headerTranslated;
  descriptionTranslated;

  async ngOnInit(){
    this.headerTranslated = await this.translate.getTranslation(this.header);
    this.descriptionTranslated = await this.translate.getTranslation(this.description);
  }
}