import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThemeModule } from '../../@theme/theme.module';
import { FloatButtonComponent } from './float-button/float-button.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ReloadButtonComponent } from './reload-button/reload-button.component';
import { UsavingsButtonComponent } from './usavings-button/usavings-button.component';

const COMPONENTS = [
  ReloadButtonComponent,
  IconButtonComponent,
  UsavingsButtonComponent,
  FloatButtonComponent
]

@NgModule({
  declarations: [...COMPONENTS, ],
  imports: [
    CommonModule,
    ThemeModule
  ],
  exports:[...COMPONENTS, CommonModule],
})
export class ButtonsModule { }
