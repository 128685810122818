import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SizePipe } from '../../../pipes/size.pipe';
import { CloudsModal } from '../../../@core/models/cloudsModal';
import { CSVRecord } from '../../../@core/models/CSVModel';
import { RequestResult } from '../../../@core/models/request-result';
import { AppSettingsService } from '../app-settings.service';
import { listCloudModal } from '../../../@core/models/listCloudModel';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { environment } from '../../../../environments/environment';
import { FlavorService } from '../../../@core/services/flavor.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { CloudsModalDiscount } from '../../../@core/models/cloudsModaldiscount';
import { CustomCloudsModal } from '../../../@core/models/customCloudsModal';
import { CustomCloudDeleteModalComponent } from './custom-cloud-delete-modal/custom-cloud-delete-modal.component';
import { AppTranslationModule } from '../../app.translation.module';
import { ContainerService } from '../../../@core/services/container.service';
import { IContract } from '../../../@core/models/DashboardModels';
import { ConfigService } from '../../../@core/services/config.service';
import { CurrencyFormatPipe } from '../../../pipes/currency-format.pipe';
import { UserDataProps } from '../../../@core/models/headerModel';
import { HttpClient } from '@angular/common/http';
import { ContractService } from '../../../@core/services/contract.service';
import { UserService } from '../../../@core/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StatusData } from '../../../@core/enum/EnumStatusData';

@Component({
  selector: 'clouds-component',
  templateUrl: './clouds-component.component.html',
  styleUrls: ['./clouds-component.component.scss'],
  providers: [SizePipe,CurrencyFormatPipe],
})
export class CloudsComponentComponent implements OnInit, OnDestroy {

  private unsubscription = new Subject()

  constructor(protected sizePipe: SizePipe, private appService: AppSettingsService,
    private containerService: ContainerService,
    private toastService: NbToastrService,
    private i18n: I18n,
    private cp: CurrencyFormatPipe,
    private config: ConfigService,
    private dialogService: NbDialogService,
    private translate: AppTranslationModule,
    private contractService: ContractService,
    private userService: UserService,
    private http: HttpClient) {
    this.initTableSetting();
    this.userService.getUser().pipe(takeUntil(this.unsubscription)).subscribe(user => {
      if (user) {
        this.userId = user.userId
        this.userHasPermission = user.isAdmin
      }
    })
    this.contractService.getCurrentContract().pipe(takeUntil(this.unsubscription)).subscribe(async contract => {
      if (contract && contract.status === StatusData.READY) {
        this.contractUuid = contract.data.ucloudIdentifier;
        this.currencyContract = contract.data.currency;

        await this.getContractContainers();
        this.mainFunc();
      }
    })

    
  }
  @ViewChild('csvReader') csvReader: any;
  public records: any[] = [];

  selectedTab = "clouds";

  actualContractCloud = undefined;

  userData: any = [];
  cloudNameDiscount: string;
  discount: any;
  cloudDiscount: any;
  showTableWithDiscount: boolean = false;
  calculatingPrices: boolean = false;
  startImport: boolean = false;
  headersArray: any = [];
  csvArr: any = [];
  importPercentage: number = 0;
  csvCanImport: boolean = false;
  loadedMessage: String;
  readingCsv: boolean = false;
  hasError: boolean = false;
  cloudName: string = "";
  submitActiveBool = false;
  submitDeleteBool = false;
  activeTab = 0;
  cloudObject: any = []
  createdCustomCloud: any;
  flavorsCustom: any = [];
  flavorsToAddCustom: any = []
  customCloudName: string;
  creatingCustomCloud: boolean = false;
  userHasPermission: boolean = false;
  clouds: CloudsModal[] = [];
  cloudTableSettings: object;

  cloudPrivateName = "";
  privateFlavorsContract = [];
  selectedContractAvailable
  privateContainers = [];
  selectedPrivateContainer: any;
  flavorsPrivateSettings: any;

  contractUuid: string;
  currencyContract: string;
  userId: number;

  async ngOnInit() {
    this.clouds = null;
  }

  ngOnDestroy(): void {
    this.unsubscription.next()
  }

  changeTab(tab: string){
    if (tab !== this.selectedTab) this.selectedTab = tab;
  }

  isPublicCloud(cloud){
    return ["aws","azure","google","ibm"].includes(cloud);
  }

  checkNameCloudExists(nameCloud){
    return this.clouds.map(cloud => cloud.name.split("@")[0]).includes(nameCloud.split("@")[0])
  }

  onSelectContract(){
    /*Selecionar os containers privados disponíveis no contrato */
    this.checkNameCloudExists(this.cloudPrivateName)
    this.privateContainers = this.selectedContractAvailable.containersFlavorsPrice;
    if (!this.selectedContractAvailable.currency){
      this.selectedContractAvailable.currency = this.config.getDefaultCurrency()
    }
    if (this.selectedPrivateContainer){
      this.selectedPrivateContainer = this.privateContainers.find(container => container.ucloudIdentifier === this.selectedPrivateContainer.ucloudIdentifier);
      this.onSelectContainer()
    }

  }

  onSelectContainer(){
    /*Fazer alteração para o padrão, caso o currency for nulo */
    this.selectedPrivateContainer.flavors = this.selectedPrivateContainer.flavors.map(flavor => {
      if (!flavor.currency){
        flavor.currency = this.config.getDefaultCurrency()
      }

      return flavor
    })
  }

  async getContractContainers(){

    let res = await this.containerService.getContractContainersWithFlavor(this.contractUuid);

    if (res.isSuccess()){
      this.privateFlavorsContract = res.getResponse();
    }
  }

  getCpuPriceContract(actualContract:string): number{
    return this.privateFlavorsContract.filter(contract=> contract.name === actualContract)[0].cpuCost;
  }

  getMemoryPriceContract(actualContract:string): number{
    return this.privateFlavorsContract.filter(contract=> contract.name === actualContract)[0].memoryCost;
  }

  getCurrecyContract(actualContract:string): string{
    let currency = this.privateFlavorsContract.filter(contract=> contract.name === actualContract)[0].currency;

    if (!currency){
      currency = this.config.getDefaultCurrency()
    }
    return currency
  }

  async initTableSetting() {
    this.cloudTableSettings = {
      columns: {
        name: {
          title: await this.translate.getTranslation('Name'),
        },
        memory: {
          title: await this.translate.getTranslation('Memory'),
          valuePrepareFunction: (data) => {
            return this.sizePipe.transform(data);
          },
          filterFunction: (cell?: any, search?: any) => {
            const cellFormat = this.sizePipe.transform(cell);
            if (cellFormat.indexOf(search) == 0 || search == '') {
              return true;
            } else {
              return false;
            }
          },
        },
        vCPU: {
          title: 'vCPU',
          filterFunction: (cell?: any, search?: any) => {
            const cellFormat = String(cell);
            if (cellFormat.indexOf(search) == 0 || search == '') {
              return true;
            } else {
              return false;
            }
          },
        },
        price: {
          title: await this.translate.getTranslation('Price'),
          valuePrepareFunction: (data, rowData) => {
            let currency = rowData.currency;
            if (!currency){
              currency = this.config.getDefaultCurrency()
            }

            const priceFormatted = this.cp.transform(data.toFixed(2), currency.trim());


            return `${priceFormatted}/Hr`;
          }
        }
      },
      actions: false,
      noDataMessage: await this.translate.getTranslation("Not found flavors for this cloud.")
    }

    this.flavorsPrivateSettings = {
      mode: 'inline',
      actions: {
        add: false,
        delete: false,
        edit: false,
      },
      columns: {
        name: {
          title: await this.translate.getTranslation('Name'),
        },
        memory: {
          title: await this.translate.getTranslation('Memory'),
          valuePrepareFunction: (data) => {
            return this.sizePipe.transform(data);
          },
        },
        vCPU: {
          title: 'vCPU',
        },
        price: {
          title: await this.translate.getTranslation('Price'),
          valuePrepareFunction: (data, rowData) => {

            const priceFormatted = this.cp.transform(data.toFixed(2), rowData.currency.trim());

            return `${priceFormatted}/Hr`;
          }
        },
      },
      noDataMessage: await this.translate.getTranslation("Not found flavors for this cloud.")
    }

    this.flavorsSettingsUpdateCloud = {
      mode: 'inline',
      edit: {
        editButtonContent: '<i class="nb-edit"></i>',
        saveButtonContent: '<i class="nb-checkmark"></i>',
        cancelButtonContent: '<i class="nb-close"></i>',
        confirmSave: true,
      },
      actions: {
        add: false,
        delete: false,
        columnTitle: await this.translate.getTranslation('Edit Price'),
      },
      columns: {
        name: {
          title: await this.translate.getTranslation('Name'),
        },
        memory: {
          title: await this.translate.getTranslation('Memory'),
          valuePrepareFunction: (data) => {
            return this.sizePipe.transform(data);
          },
        },
        vCPU: {
          title: 'vCPU',
        },
        price: {
          title: await this.translate.getTranslation('Price'),
          valuePrepareFunction: (data, rowData) => {
            const priceFormatted = this.cp.transform(Number(Number(data).toFixed(2)), rowData.currency.trim());

            return `${priceFormatted}/Hr`;
          }
        },
      },
      noDataMessage: await this.translate.getTranslation("Not found flavors for this cloud.")
    }
  }

  deleteCloud(cloud: CloudsModal) {

    let cloudNameToDelete = cloud.name;
    if (cloud.hasOwnProperty("contract")){
      cloudNameToDelete = `${cloudNameToDelete}@${cloud.contract}`
    }

    if (cloud.hasOwnProperty("uuid")){
      cloudNameToDelete = `${cloudNameToDelete}@${cloud.uuid}`
    }

    this.submitDeleteBool = true;
    return this.appService.deleteCloud(cloudNameToDelete,this.contractUuid,this.userId).subscribe(async (res: RequestResult) => {
      if (res.isSuccess()) {
        this.clouds = this.clouds.filter(c => c.name !== cloud.name)
        this.submitDeleteBool = false;
        this.cloudObject = [];
        this.clouds.forEach(cloud => {
          let cloudInfo = {
            name: cloud.name,
            isActive: cloud.isActive
          };
          this.cloudObject.push(cloudInfo)
        });
        sessionStorage.removeItem('clouds');
        sessionStorage.setItem('clouds', JSON.stringify(this.cloudObject));
        this.clouds[0]['activeTab'] = true;
        this.toastService.success(await this.translate.getTranslation("Cloud") + ` ${cloud.name} ` + await this.translate.getTranslation("deleted successfully."), await this.translate.getTranslation('Deleted!'));
      } else {
        this.submitDeleteBool = false;
        this.captureError(res.getResponse(), 'delete');
        this.toastService.danger(await this.translate.getTranslation(`Failed on try delete this cloud.`), this.i18n('Ops!'));
      }

    })
  }

  openDeleteModal(cloud: CloudsModal) {
    this.dialogService.open(CustomCloudDeleteModalComponent, {
      context: {
        cloud: cloud,
      },
    }).onClose.subscribe(res => {
      if (res) {
        this.deleteCloud(cloud);
      }
    }
    );
  }

  toggleActivity(cloudAction) {
    this.clouds.forEach((cloud, index) => {
      if (cloud.name.toLowerCase() == cloudAction.name.toLowerCase()) {
        cloud.isActive = !cloud.isActive;
      }
    })
  }

  async createPrivateContainerCloud(){
    this.importingFlavors = true;

    let name = `${this.cloudPrivateName}@${this.selectedContractAvailable.name}`

    let data: CustomCloudsModal = {
      name: name,
      type: this.cloudPrivateName,
      customFlavors: this.selectedPrivateContainer.flavors,
      contractUuid: this.contractUuid,
      userId: this.userId,
    };


    if (this.checkNameCloudExists(data.name)){
      this.toastService.danger(await this.translate.getTranslation('Cloud name already exist! Try another.'), this.i18n('Ops!'));
      this.importingFlavors = false;
    }else{
      await this.appService.importPrivateCloud(data).subscribe(async (res: RequestResult) => {
        if (res.isSuccess()) {
          this.cloudPrivateName = "";
          this.privateContainers = [];
          this.selectedPrivateContainer = undefined;
          this.selectedContractAvailable = undefined;
          this.toastService.success(await this.translate.getTranslation('Cloud imported!'), await this.translate.getTranslation('Good!'));

          this.mainFunc();


        } else {
          this.captureError(res.getResponse());
          this.toastService.danger(await this.translate.getTranslation('Failed on importing cloud.'), this.i18n('Ops!'));
        }

        this.importingFlavors = false;

      });
    }

  }

  async createCustomCloud() {
    this.creatingCustomCloud = true;
    this.createdCustomCloud.flavors.forEach(flavor => {
      flavor.price = Number(flavor.price);
      delete flavor.diskType;
      delete flavor.location;
      delete flavor.id;
      delete flavor.reservedCostOneYear;
      delete flavor.versionDate;
      delete flavor.type;
    });


    let newNameCloud = this.customCloudName;
    let typeCloudToImport: string =  this.createdCustomCloud.name;
    this.flavorsToAddCustom = this.createdCustomCloud.flavors;

    if (this.createdCustomCloud.contract) {
      typeCloudToImport += `@${this.createdCustomCloud.contract}`
    }

    if (this.createdCustomCloud.uuid) {
      typeCloudToImport += `@${this.createdCustomCloud.uuid}`
    }


    // TODO ERROR Provavelmente vai ter um erro aqui
    let data: CustomCloudsModal = {
      name: newNameCloud,
      typeCloudToImport: typeCloudToImport,
      customFlavors: this.flavorsToAddCustom,
      userId: this.userId,
      contractUuid: this.contractUuid,
    };

    if (this.checkNameCloudExists(data.name)){
      this.toastService.danger(await this.translate.getTranslation('Cloud name already exist! Try another.'), this.i18n('Ops!'));
      this.creatingCustomCloud = false;
    } else {
      await this.appService.importCloudCustom(data).subscribe(async (res: RequestResult) => {
        this.closeErrorAlert();
        if (res.isSuccess()) {
          this.toastService.success(await this.translate.getTranslation('Cloud imported!'), await this.translate.getTranslation('Good!'));
          this.creatingCustomCloud = false;
          this.customCloudName = '';
          this.createdCustomCloud = '';
          this.flavorsToAddCustom = [];
          this.mainFunc();
        } else {
          this.loadedMessage = null;
          this.captureError(res.getResponse());
          this.toastService.danger(await this.translate.getTranslation('Failed on importing cloud.'), this.i18n('Ops!'));
          this.creatingCustomCloud = false;

        }
      });
    }
  }

  submitActivity() {
    this.submitActiveBool = true;
    let cloudsCopy = [];
    let number;
    for (let cloud of this.clouds) {
      cloud.isActive == false ? number = 0 : number = 1;
      cloudsCopy.push({ id: cloud.id, isActive: number, name: cloud.name });
    };

    this.appService.updateClouds({contractUuid: this.contractUuid,userId: this.userId,flavors: cloudsCopy}).subscribe(async (res: RequestResult) => {
      if (res.isSuccess()) {
        this.toastService.success(await this.translate.getTranslation('Activity clouds updated!'), await this.translate.getTranslation('Success'));
        sessionStorage.removeItem('clouds');
        sessionStorage.setItem('clouds', JSON.stringify(cloudsCopy));
      } else {
        const response = await res.getResponse()
        this.toastService.danger(await this.translate.getTranslation('Error occured.') + ` ${response.error.message}`, this.i18n('Ops!'));
      }
      this.submitActiveBool = false;
    })
  }

  cloudImages(name: string = 'custom') {

    let cloudImages = {
      aws: '/assets/images/aws.png',
      azure: '/assets/images/azure.png',
      google: '/assets/images/google.png',
      ibm: '/assets/images/ibm.jpg',
      custom: '/assets/images/custom.png'
    };

    switch (name) {
      case 'aws':
        return cloudImages.aws;
      case 'azure':
        return cloudImages.azure;
      case 'google':
        return cloudImages.google;
      case 'ibm':
        return cloudImages.ibm;
      default:
        return cloudImages.custom;
    }

  }


  onRemoveImage() {
    this.csvArr = [];
    this.loadedMessage = null;
    this.hasError = false;
    this.showErrorAlert = false;
    this.csvCanImport = false;

  }

  importingFlavors = false;
  async submitImportCsv() {
    this.closeErrorAlert();
    this.importErrors = [];
    this.importingFlavors = true;
    if (this.csvArr.length < 1) {
      this.importErrors.push(await this.translate.getTranslation('The csv is empty.'));
      this.importingFlavors = false;
      return;
    }
    if (this.cloudName.length < 3 || this.cloudName.length > 20) {
      this.importErrors.push(await this.translate.getTranslation('Cloud name must be more than 3 characters and less than 20.'));
      this.importingFlavors = false;
      return;
    }
    let data: CloudsModal = {
      name: this.cloudName,
      flavors: this.csvArr,
      contractUuid: this.contractUuid,
      userId: this.userId,
      typeImport: 'normal'
    }

    if (this.checkNameCloudExists(data.name)){
      this.toastService.danger(await this.translate.getTranslation('Cloud name already exist! Try another.'), this.i18n('Ops!'));
      this.importingFlavors = false;
    }else{
      this.appService.importCloud(data).subscribe(async (res: RequestResult) => {
        this.closeErrorAlert();
        if (res.isSuccess()) {
          this.toastService.success(await this.translate.getTranslation('Cloud imported!'), await this.translate.getTranslation('Good!'));
          this.mainFunc();
          this.cloudName = '';
        } else {
          this.loadedMessage = null;
          this.captureError(res.getResponse());
          this.toastService.danger(this.i18n('Failed on try import cloud.'), this.i18n('Ops!'));
          this.cloudName = '';
        }
        this.csvCanImport = false;
        this.importingFlavors = false;
      })
    }
  }

  importErrors = [];
  deleteErrors = [];
  async captureError(res, type = `import`) {
    if (type == 'import') {
      this.importErrors = [];
      if (res.hasOwnProperty('error')) {
        if (res.error.hasOwnProperty('message')) {
          this.importErrors.push(res.error.sqlMessage);
        }
      } else {
        this.importErrors.push(await this.translate.getTranslation('An unexpected error has occurred.'));
      }
    } else if (type == 'delete') {
      this.deleteErrors = [];
      if (res.hasOwnProperty('error')) {
        if (res.error.hasOwnProperty('message')) {
          this.deleteErrors.push(res.error.message);
        }
      } else {
        this.deleteErrors.push(await this.translate.getTranslation('An unexpected error has occurred.'));
      }
    }
  }

  async uploadListener($event: any) {
    this.hasError = false;
    this.showErrorAlert = false;

    let text = [];
    this.headersArray = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = async () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        let headersRow = this.getHeaderArray(csvRecordsArray);

        this.records = await this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
        this.csvCanImport = true;
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };

    } else {
      alert(await this.translate.getTranslation("Please import valid .csv file."));
      this.fileReset();
    }
  }



  async getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    this.loadedMessage = null;
    this.csvArr = [];
    this.readingCsv = true;

    for (let i = 1; i < csvRecordsArray.length; i++) {
      let curruntRecord = (<string>csvRecordsArray[i]).split(';');
      if (curruntRecord.length == headerLength) {
        let csvRecord: CSVRecord = new CSVRecord();

        const iName = this.headersArray.findIndex(r => r.toLowerCase() == "name");
        const iMemory = this.headersArray.findIndex(r => r.toLowerCase() == "memory");
        const iVcpu = this.headersArray.findIndex(r => r.toLowerCase() == "vcpu");
        const iPrice = this.headersArray.findIndex(r => r.toLowerCase() == "price");
        const iCurrency = this.headersArray.findIndex(r => r.toLowerCase() == "currency");
        const iOsType = this.headersArray.findIndex(r => r.toLowerCase() == "os");
        const iLocation = this.headersArray.findIndex(r => r.toLowerCase() == "location");

        let availableOs = ['windows', 'linux', 'sles', 'rhel'];
        let availableCurr = ['brl', 'usd'];
        if (iName == -1 ||
          iMemory == -1 ||
          iVcpu == -1 ||
          curruntRecord.length < 6 ||
          iCurrency == -1 ||
          iOsType == -1 ||
          (iOsType != -1
            && !availableOs.includes(curruntRecord[iOsType].toLowerCase())) ||
          !availableCurr.includes(curruntRecord[iCurrency].toLowerCase())) {
          this.hasError = true;
          this.showErrorAlert = true;
          this.readingCsv = false;
          return false;
        }

        csvRecord.name = curruntRecord[iName].trim();
        csvRecord.memory = parseFloat(curruntRecord[iMemory].trim().replace(',', ''));
        csvRecord.vCPU = parseFloat(curruntRecord[iVcpu].trim());
        csvRecord.price = iPrice != -1 ? parseFloat(curruntRecord[iPrice].trim().replace(',', '.')) : 0;
        csvRecord.currency = iCurrency != -1 ? curruntRecord[iCurrency].trim() : 'USD';
        csvRecord.osType = iOsType != -1 ? curruntRecord[iOsType].trim() : 'linux';
        csvRecord.location = curruntRecord[iLocation];

        this.csvArr.push(csvRecord);
      }
    }

    if (this.csvArr.length == 0) {
      this.hasError = true;
      this.showErrorAlert = true;
      this.readingCsv = false;
      return false;
    }

    this.loadedMessage = await this.translate.getTranslation('Good! We found') + ` ${this.csvArr.length} ` + await this.translate.getTranslation('flavors in this CSV.');
    this.readingCsv = false;
    return this.csvArr;
  }
  showErrorAlert: boolean = false;

  closeErrorAlert() {
    this.showErrorAlert = false;
  }


  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(';');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
      this.headersArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  listCloudErr = { error: false, message: "" };

  listClouds(params: listCloudModal = { showAll: true, showFlavors: false }) {
    return new Promise<void>(resolve => {
      
      let cloudsAll: any = [];
      this.appService.listClouds(params, this.userId).subscribe(async (res: RequestResult) => {
        if (res.isSuccess()) {
          this.clouds = res.getResponse();

          this.clouds = this.clouds.map(cloud => {

            cloud.paginateInfo = {
              total: null,
              perPage: 10,
              current: 1,
              lastPage: null
            }

            if (cloud.name.includes("@")){
              const splittedName = cloud.name.split("@")
              cloud.name = splittedName[0]
              if (splittedName.length === 3){
                cloud["contract"] = splittedName[1]
                cloud["uuid"] = splittedName[2]
              } else {
                cloud["uuid"] = splittedName[1]
              }

            }

            return cloud;
          })

          sessionStorage.removeItem('clouds');

          for (const cloud of this.clouds) {
            let cloudInfo = { name: cloud.name, isActive: cloud.isActive };
            cloudsAll.push(cloudInfo);
          }

          sessionStorage.setItem('clouds', JSON.stringify(cloudsAll));

          resolve();
        } else {
          this.listCloudErr.error = true;
          this.listCloudErr.message = await this.translate.getTranslation("Error on get virtual machines ocurred.");
          resolve();
        }
      })
    })
  }

 

  setVariables() {
    return new Promise<void>(resolve => {
      this.loadedMessage = null;
      resolve();
    })
  }

  async mainFunc() {
    await this.setVariables();
    await this.listClouds({ showAll: true, showFlavors: true });
  }

  async eventHandleChild(event){
    await this.mainFunc();
  }

  async changeCustomCloud(object){
    this.flavorsCustom = object.flavors
  }

  onEditConfirm(event) {
    let existData: boolean = false;
    let counter: number = 0;
    let repeatedRow: number;
    this.flavorsToAddCustom.forEach(row => {
      if (row.id == event.newData.id) {
        existData = true;
        repeatedRow = counter;
      }
      counter = counter + 1;
    });

    if (!existData) {
      this.flavorsToAddCustom.push(event.newData);

    } else {
      this.flavorsToAddCustom.splice(repeatedRow, 1);
      this.flavorsToAddCustom.push(event.newData);
    }
    existData = false;
    counter = 0;
    event.confirm.resolve();
  }

  /* ng2 settings */
  flavorsSettingsUpdateCloud: object;

}
